<template>
  <AppPanel
    id="EquipmentDetails"
    label="Troubleshooting Steps"
    variant="alternate"
    :open="true"
    :collapsible="true">
    <div
      id="EquipmentDetails"
      class="grid grid-cols-1 gap-4">
      <div class="grid grid-cols-1 gap-4">
        <p>
          If you are experiencing issues connecting to the Internet through a wired or WiFi
          connection, here are several troubleshooting steps to help fix the issue.
        </p>
        <p>
          Please note that if a modem or gateway is making a loud sound, producing smoke or appears
          misshapen from heat, unplug the device from the electrical outlet and contact technical
          support immediately.
        </p>
        <div>
          <div class="mb-1 text-lg font-bold">Check the power source</div>
          <p>
            If there are no lights lit up on your modem or gateway, make sure that you have power in
            the connected electrical outlet. Don't forget to check any switches or circuit breakers
            that the outlet may be connected to.
          </p>
        </div>
        <div>
          <div class="mb-1 text-lg font-bold">Reboot or reset the modem or gateway</div>
          <p class="mb-4">
            “Did you try unplugging it and plugging it back in?” We know, you’ve heard this a
            thousand times before, but it really is the most effective method for troubleshooting
            modem-related issues. If the lights on the modem or gateway are functioning properly,
            try restarting it manually by following these steps:
          </p>
          <ol class="list-inside list-decimal py-4">
            <li class="mb-2">Unplug the power cord from the electrical power outlet.</li>
            <li class="mb-2">Wait one full minute.</li>
            <li class="mb-2">
              Plug the power cord back into the modem or gateway and it should automatically come
              back online.
            </li>
          </ol>
          <p>
            You can also send a reset signal to your modem or gateway below. If possible, we advise
            you to first reboot your equipment by physically unplugging your modem and plugging it
            back in as this solves the majority of connectivity issues.
          </p>
        </div>

        <div
          v-if="allEquipments.length > 0"
          class="flex flex-col items-start">
          <BzlButton
            v-for="eq in allEquipments"
            :key="eq.serialNumber"
            variant="primary"
            :disabled="getActualStatus(eq) !== 'online'"
            @click="reboot(eq)"
            class="mt-2">
            Reset {{ eq.make }} {{ eq.model }}
          </BzlButton>
        </div>
      </div>
    </div>
  </AppPanel>
</template>

<script>
  export default {
    name: 'EquipmentDetails',
    props: {
      allEquipments: {
        type: Array,
        default: () => [],
      },
      rebootList: {
        type: Array,
        default: () => [],
      },
    },
    methods: {
      getActualStatus(eq) {
        return this.rebootList.includes(eq.serialNumber)
          ? 'rebooting'
          : eq.status
          ? 'online'
          : 'offline'
      },
      reboot(eq) {
        this.$emit('reboot', eq)
        this.trackEvent('trigger_reset_modem_clicked')
      },
    },
  }
</script>
