<template>
  <div
    class="cursor-pointer"
    :class="dynamicClasses">
    <div class="mt-4 flex flex-row items-center justify-between rounded border border-gray p-4">
      <div class="flex flex-row items-center justify-between gap-x-4">
        <BzlIcon
          class="text-xl text-blue"
          v-if="isSelected && isSelectable"
          name="record-circle"></BzlIcon>
        <BzlIcon
          class="text-xl text-gray"
          v-else-if="displayCheckbox"
          name="circle"></BzlIcon>
        <template v-if="isPaymentMethod">
          <div v-if="paymentMethod && paymentType === 'CARD'">
            <div class="flex items-center gap-2 text-lg font-bold">
              <BzlIcon
                :customIconName="icon.name"
                :src="icon.src"></BzlIcon>
              {{ paymentMethod.displayName }}
            </div>
            <span>
              {{ paymentMethod.accountHolder }} {{ paymentMethod.lastFour }} &#x2022; Expires
              {{ paymentMethod.expiresOn }}</span
            >
          </div>
          <div v-if="paymentMethod && paymentType === 'EFT'">
            <div class="text-lg font-bold">
              {{ paymentMethod.displayName }} ending in {{ paymentMethod.accountNumber }}
            </div>
          </div>
        </template>
        <template v-else>
          <slot name="label"></slot>
        </template>
      </div>
      <div>
        <slot name="value"></slot>
      </div>
    </div>
  </div>
</template>

<script>
  import calendarIcon from './calendar-icon.svg'

  export default {
    name: 'AppRadioPanel',
    props: {
      isSelectable: {
        type: Boolean,
        default: true,
      },
      isSelected: {
        type: Boolean,
        default: false,
      },
      isDisabled: {
        type: Boolean,
        default: false,
      },
      isPaymentMethod: {
        type: Boolean,
        default: false,
      },
      paymentMethod: {
        type: Object,
        required: false,
        default: () => ({}),
      },
      displayCheckbox: {
        type: Boolean,
        default: true,
      },
    },
    data: () => ({
      icon: {
        name: 'Credit Card',
        src: calendarIcon,
      },
    }),
    computed: {
      dynamicClasses() {
        const classes = []

        if (this.isDisabled) {
          classes.push(`opacity-30 cursor-not-allowed pointer-events-none`)
        } else {
          classes.push('opacity-100')
        }

        return classes.join(' ')
      },
      paymentType() {
        return this.paymentMethod?.paymentType
      },
    },
  }
</script>

<style scoped>
  .BzlIconButton::part(base) .icon-button--focused {
    @apply bg-salmon;
  }
</style>
