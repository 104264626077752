<template>
  <div
    class="space-y-4"
    :class="dynamicClasses">
    <div class="flex items-center gap-4">
      <template v-if="!available">
        <slot name="button-unavailable"></slot>
        <BzlSwitch disabled></BzlSwitch>
      </template>
      <template v-else-if="enabled">
        <slot name="button-enabled"></slot>
        <BzlSwitch
          checked
          @click.prevent="$emit('disable')"></BzlSwitch>
      </template>
      <template v-else>
        <slot name="button-disabled"></slot>
        <BzlSwitch @click.prevent="click()"></BzlSwitch>
      </template>
    </div>
    <p class="text-sm">
      <slot name="description"></slot>
    </p>
    <div
      v-if="!available"
      class="bg-red-100 text-sm">
      <slot name="status-unavailable"></slot>
    </div>
    <div
      v-else-if="enabled"
      class="bg-green-100 text-sm">
      <slot name="status-enabled"></slot>
    </div>
    <div
      v-else
      class="bg-gray-light text-sm">
      <slot name="status-disabled"></slot>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'DiscountItem',
    props: {
      discountName: {
        type: String,
        required: true,
      },
      available: {
        type: Boolean,
        default: false,
      },
      enabled: {
        type: Boolean,
        default: false,
      },
    },
    computed: {
      dynamicClasses() {
        const classes = []

        if (this.available) {
          if (this.enabled) {
            classes.push('border-green-600')
          } else {
            classes.push('border-blue')
          }
        } else {
          classes.push('border-red-600')
        }

        return classes.join(' ')
      },
    },
    methods: {
      click() {
        if (this.discountName === 'Ecosave') {
          this.trackEvent('trigger_enrolled_paperless_billing')
        }
        this.$emit('enable')
      },
    },
  }
</script>
