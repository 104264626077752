<template>
  <Step heading="Confirmation">
    <hr />
    <div v-if="responseStatus">
      <div v-if="windowType === 'cancel'">Your Promise to Pay has been successfully canceled.</div>
      <div v-else>Your Promise to Pay was submitted successfully</div>
    </div>
    <div v-else>
      <div v-if="windowType === 'cancel'">
        There was a problem canceling your Promise to Pay, please try again. If this problem
        persists, please contact Customer Service at
      </div>
      <div v-else>
        {{ trackEvent('trigger_error_making_a_promise_to_pay') }}
        There was a problem scheduling your Promise to Pay, please try again. If this problem
        persists, please contact Customer Service at
        <a
          href="tel:18885369600"
          class="underline"
          >1-888-536-9600</a
        >.
      </div>
    </div>
    <template #footer>
      <BzlButton @click="$emit('close')"> Close </BzlButton>
    </template>
  </Step>
</template>

<script>
  import Step from './step.vue'

  export default {
    name: 'PromiseToPayStep3',
    components: {
      Step,
    },
    props: {
      windowType: {
        type: String,
        default: '',
      },
      responseStatus: {
        type: Boolean,
        default: false,
      },
    },
  }
</script>
