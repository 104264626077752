<template>
  <AppPanel
    label="Current Speed"
    :externalLink="url">
    <div
      class="mt-4 flex w-full flex-col items-center justify-between gap-4 p-2 lg:mt-6 lg:items-start">
      <div class="flex flex-col items-center space-y-4 md:items-start">
        <BzlInternetSpeedGauge
          :speed="currentSpeed"
          :title="`${currentSpeed} Mbps`"
          :show-disclaimer="true"
          :subtitle="idealFor"
          @on-disclaimer-click="modals.internetSpeedDisclaimer = true"></BzlInternetSpeedGauge>
        <div class="text-center font-bold">Want to Breeze faster?</div>
      </div>
      <BzlButton
        variant="primary"
        :mFull="true"
        :href="url">
        Discover other speeds
      </BzlButton>
    </div>

    <BzlModal
      :open="modals.internetSpeedDisclaimer"
      label="Internet Speed Disclaimer"
      @sl-after-hide="modals.internetSpeedDisclaimer = false">
      <div class="space-y-4">
        <p>
          Speeds are stated for download only over wired connections. Wireless speeds will be
          substantially lower. Actual Internet speeds, including wireless speeds, may vary due to
          conditions outside of network control and congestion, including customer location,
          equipment, and access through a wireless connection, and are not guaranteed; please see
          Breezeline’s Network Management Disclosure on the
          <a
            href="https://www.breezeline.com/policies-agreements"
            target="_blank">
            Policies & Agreements
          </a>
          page for details. Actual speeds for Unlimited Gigafast Internet Service may vary due to
          subscriber device limitations.
        </p>
        <div class="flex w-full flex-row justify-around">
          <BzlButton @click="modals.internetSpeedDisclaimer = false"> Cancel </BzlButton>
        </div>
      </div>
    </BzlModal>
  </AppPanel>
</template>

<script>
  export default {
    name: 'UpgradeBanner',
    props: {
      isEligible: {
        type: Boolean,
        required: false,
        default: false,
      },
      url: {
        type: String,
        required: true,
      },
      currentSpeed: {
        type: Number,
        required: true,
      },
      idealFor: {
        type: String,
        required: true,
      },
    },
    data: () => ({
      showModal: false,
      modals: {
        internetSpeedDisclaimer: false,
      },
    }),
    methods: {
      toggleModal() {
        this.showModal = !this.showModal

        // Always start with current email
        this.newEmail = this.customerInfo.emailAddress

        if (this.showModal) {
          // Note: rendering issue, need to wait for repaint before focus() can work
          setTimeout(() => this.$refs.newEmail.focus(), 0)
        }
      },
    },
  }
</script>
