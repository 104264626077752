<template>
  <main>
    <AppPanel
      label="Billing & Payments"
      variant="transparent"
      spacing="none"
      padding="none"
      alignment="center"
      class="lg:hidden"></AppPanel>

    <AppPromoBannerModal
      v-if="isAndroid() || isIOS()"
      :open="isMobileAppBannerVisible"
      @close-mobile-app-banner="closeMobileAppBanner()"></AppPromoBannerModal>

    <AppPromoBanner
      v-else
      :open="isMobileAppBannerVisible"
      @close-mobile-app-banner="closeMobileAppBanner()"></AppPromoBanner>

    <AppPanel label="Billing">
      <AppSuspense :dependencies="['billing']">
        <template #loading>
          <AppPanel variant="alternate">
            <p class="animate-pulse text-center">Loading billing...</p>
          </AppPanel>
        </template>
        <template #error>
          <AppPanel variant="alternate">
            <p class="text-center text-error">Unable to load billing. Please try again later.</p>
          </AppPanel>
        </template>
        <template #ready>
          <MakeAPaymentSection
            :disable-autopay-override="disableAutopayOverride"
            @close-autopay="
              disableAutopayOverride = false
              enableAutopayOverride = false
            "></MakeAPaymentSection>
        </template>
      </AppSuspense>

      <AppSuspense :dependencies="['billing']">
        <template #loading>
          <AppPanel
            variant="alternate"
            label="Payment Methods">
            <p class="animate-pulse text-center">Loading payment methods...</p>
          </AppPanel>
        </template>
        <template #error>
          <AppPanel variant="alternate">
            <p class="text-center text-error">
              Unable to load payment methods. Please try again later.
            </p>
          </AppPanel>
        </template>
        <template #ready>
          <PaymentMethodsSection
            @disable-autopay="disableAutopayOverride = true"></PaymentMethodsSection>
        </template>
      </AppSuspense>

      <AppSuspense :dependencies="['billing']">
        <template #loading>
          <AppPanel
            variant="alternate"
            label="Statement History">
            <p class="animate-pulse text-center">Loading statement history...</p>
          </AppPanel>
        </template>
        <template #error>
          <AppPanel variant="alternate">
            <p class="text-center text-error">
              Unable to load statement history. Please try again later.
            </p>
          </AppPanel>
        </template>
        <template #ready>
          <StatementHistorySection></StatementHistorySection>
        </template>
      </AppSuspense>

      <AppSuspense :dependencies="['billing']">
        <template #loading>
          <AppPanel
            variant="alternate"
            label="Payment History">
            <p class="animate-pulse text-center">Loading payment history...</p>
          </AppPanel>
        </template>
        <template #error>
          <AppPanel variant="alternate">
            <p class="text-center text-error">
              Unable to load payment history. Please try again later.
            </p>
          </AppPanel>
        </template>
        <template #ready>
          <PaymentHistorySection></PaymentHistorySection>
        </template>
      </AppSuspense>
    </AppPanel>
  </main>
</template>

<script>
  // Split the BillingPage up into sections
  import MakeAPaymentSection from './MakeAPaymentSection.vue'
  import PaymentMethodsSection from './PaymentMethodsSection.vue'
  import PaymentHistorySection from './PaymentHistorySection.vue'
  import StatementHistorySection from './StatementHistorySection.vue'
  import AppPromoBanner from '../../components/AppPromoBanner/index.vue'
  import AppPromoBannerModal from '../../components/AppPromoBannerModal/index.vue'

  import hasMobileAppBanner from '#/shared/mixins/hasMobileAppBanner.js'

  export default {
    name: 'BillingPage',
    components: {
      MakeAPaymentSection,
      PaymentMethodsSection,
      PaymentHistorySection,
      StatementHistorySection,
      AppPromoBanner,
      AppPromoBannerModal,
    },
    mixins: [hasMobileAppBanner],
    data: () => ({
      disableAutopayOverride: false,
    }),
    methods: {
      enrollInAutopay() {
        this.enableAutopayOverride = true
      },
    },
  }
</script>
