<template>
  <div
    class="fixed inset-0 z-50 flex h-full w-full items-center justify-center bg-black bg-opacity-75 p-4">
    <div
      class="relative max-h-full w-full max-w-prose overflow-y-auto rounded-md bg-white text-navy shadow"
      :class="innerClass ? innerClass : `space-y-4 p-4`">
      <slot
        v-bind="$props"
        v-on="$listeners"></slot>
      <slot name="inner-modal"></slot>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'AppModal',
    props: {
      innerClass: {
        type: String,
        default: null,
      },
    },
  }
</script>
