<template>
  <PaymentHistory
    :payments="$store.state.billing.allPayments"
    :promise-to-pay="$store.state.billing.promiseToPayPayments"
    @view="viewPayment($event)">
    <ViewPayment
      :open="modals.ViewPayment"
      :payment="paymentToShow"
      :promise-to-pay="$store.state.billing.promiseToPayPayments"
      @cancel-payment="cancelScheduledPayment"
      @dismiss="hidePayment()"></ViewPayment>
  </PaymentHistory>
</template>

<script>
  import PaymentHistory from '../../components/PaymentHistory/index.vue'
  import ViewPayment from '../../components/PaymentHistory/view.vue'

  export default {
    name: 'PaymentHistorySection',
    components: {
      PaymentHistory,
      ViewPayment,
    },
    data: () => ({
      modals: {
        ViewPayment: false,
      },
      paymentToShow: null,
    }),
    methods: {
      viewPayment(payment) {
        this.paymentToShow = payment

        // only for scheduled payments, because they don't have the full payment details..
        if (payment.isScheduled) {
          // bind the actual payment method object to this scheduled payment so we can display it as well
          this.paymentToShow.paymentMethod = this.$store.getters['paymentMethods/getPaymentMethod'](
            payment.paymentMethodId
          )
        }

        this.modals.ViewPayment = true
      },
      hidePayment() {
        console.log('Clicked', (this.paymentToShow = null), (this.modals.ViewPayment = false))
        this.paymentToShow = null
        this.modals.ViewPayment = false
      },
      cancelScheduledPayment(payment, callback) {
        return this.$store.dispatch('billing/cancelScheduledPayment', payment).then(callback)
      },
    },
  }
</script>
