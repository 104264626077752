<template>
  <BzlModal
    label="AutoPay"
    @sl-after-hide="$emit('dismiss')">
    <div class="flex flex-col gap-4">
      <template v-if="paymentMethods.length > 0">
        <p>
          AutoPay will take effect on your next statement. Your full balance due will be paid
          automatically each month using the selected payment method.
        </p>
        <div class="space-y-2">
          <label class="block font-bold"> Payment method </label>
          <AppRadioPanel
            v-for="pm in paymentMethods"
            :key="pm.id"
            :is-payment-method="true"
            :payment-method="pm"
            :is-selected="pm === selectedPaymentMethod"
            @click.native="select(pm)"></AppRadioPanel>
        </div>
        <div class="flex w-full flex-row justify-between">
          <BzlButton
            variant="text"
            @click="$emit('dismiss')">
            Cancel
          </BzlButton>
          <BzlButton
            variant="primary"
            :disabled="selectedPaymentMethod === null"
            @click.prevent="confirm()">
            Confirm
          </BzlButton>
        </div>
      </template>
      <template v-else>
        <div>Please add a payment method first before setting up AutoPay.</div>
        <div class="flex w-full flex-row justify-center">
          <BzlButton @click="$emit('dismiss')"> OK </BzlButton>
        </div>
      </template>
    </div>
  </BzlModal>
</template>

<script>
  export default {
    name: 'EnableAutopay',
    props: {
      paymentMethods: {
        type: Array,
        default: () => [],
      },
    },
    data: () => ({
      selectedPaymentMethod: null,
    }),
    mounted() {
      // this.trackEvent('trigger_enable_autopay_clicked')
    },
    methods: {
      select(pm) {
        this.selectedPaymentMethod = pm
      },
      confirm() {
        this.trackEvent('PT_SUBMIT_AUTOPAY')
        this.$emit('confirm', this.selectedPaymentMethod, () => {
          this.$emit('dismiss')
        })
      },
    },
  }
</script>
