<template>
  <div
    class="fixed inset-0 z-50 flex h-full w-full items-center justify-center bg-black bg-opacity-75 p-4">
    <div class="rounded-md bg-white">
      <div
        class="relative m-4 flex max-h-full max-w-full max-w-prose flex-col items-center gap-4 overflow-y-auto rounded-md border-4 font-bold shadow"
        :class="dynamicClasses">
        {{ message }}

        <BzlButton @click="$store.dispatch('alerts/setAlert')">
          {{ buttonText }}
        </BzlButton>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'AppAlert',
    props: {
      message: {
        type: String,
        default: 'Sorry, something went wrong. Please try again.',
      },
      buttonText: {
        type: String,
        default: 'OK',
      },
      variant: {
        type: String,
        default: 'error',
        validator: (val) =>
          [
            'info', // light blue background, dark blue text
            'error', // salmon background, red text
            'success', // light green background, dark green text
          ].includes(val),
      },
      size: {
        type: String,
        default: 'large',
        validator: (val) => ['small', 'medium', 'large'].includes(val),
      },
    },
    computed: {
      dynamicClasses() {
        const classes = []

        // padding
        classes.push(
          {
            small: `px-2 py-1`,
            medium: `px-3 py-2`,
            large: `p-4`,
          }[this.size]
        )

        // text size
        classes.push(
          {
            small: `text-xs`,
            medium: `text-base`,
            large: `text-lg`,
          }[this.size]
        )

        classes.push(
          {
            success: `text-green-600 bg-green-200 border-green-600`,
            error: `text-red-500 bg-red-100 border-red-500`,
            info: `text-blue bg-blue-100 border-blue`,
          }[this.variant]
        )

        return classes.join(' ')
      },
    },
  }
</script>
