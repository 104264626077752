<template>
  <AppPanel
    label="Breezeline Mobile"
    :externalLink="mobileLink"
    :mobileNewText="this?.headingText == 'Now Introducing Breezeline Mobile!'">
    <div class="mt-4 flex w-full flex-row items-center gap-4 p-2 lg:mt-6 lg:items-start">
      <img
        class="testing hidden 2xl:block"
        src="./mobile-banner.png" />

      <div class="flex flex-col items-start gap-4">
        <p class="heading-text">
          {{ headingText }}
        </p>
        <p class="css-fix">
          {{ mobileParagraph }}
        </p>
        <div class="w-full">
          <BzlButton
            @click="trackEvent(triggerString)"
            :mFull="true"
            variant="primary"
            target="_blank"
            :href="mobileLink">
            {{ buttonText }}
          </BzlButton>
        </div>
      </div>
    </div>
  </AppPanel>
</template>

<script>
  export default {
    name: 'MobileBanner',
    data: () => ({
      headingText: null,
      mobileParagraph: null,
      buttonText: null,
      mobileLink: null,
      triggerString: null,
    }),
    mounted() {
      // eligible: 'Now Introducing Breezeline Mobile!',
      // outside: 'Breezeline Mobile Coming Soon!',
      // modify: 'Manage your Mobile service with my Breezline Mobile',

      // eligible: 'Save up to $10/mo on your Breezeline service and enjoy exclusive mobile pricing.\nChoose the plan that works for each member of your family',
      // modify: 'Modify your mobilie plan, monitor data usage, purchase additional data, manage you mobile account, and more!'

      // eligible: 'Learn More',
      // modify: 'Manage Breezeline Mobile'
      if (
        this.$store.state.mobile.mobileInfo.mobileSummary == 'PLAN' ||
        this.$store.state.mobile.mobileInfo.mobileSummary == 'ACTIVATION' ||
        this.$store.state.mobile.mobileInfo.mobileSummary == 'INTEREST'
      ) {
        this.headingText = 'Manage your Mobile service with My Breezeline Mobile!'
        this.mobileParagraph =
          'Modify your mobile plan, monitor data usage, purchase additional data, manage your mobile account, and more!'
        this.buttonText = 'Manage Breezeline Mobile'
        this.mobileLink = 'https://mobile.breezeline.com/login'
        this.triggerString = 'trigger_manage_mobile_CTA'
      } else if (this.$store.state.mobile.mobileInfo.mobileAvailable == true) {
        this.headingText = 'Now Introducing Breezeline Mobile!'
        this.mobileParagraph =
          'Save up to $10/mo on your Breezeline service and enjoy exclusive mobile pricing.\nChoose the plan that works for each member of your family.'
        this.buttonText = 'Learn More'
        this.mobileLink = 'https://mobile.breezeline.com/'
        this.triggerString = 'trigger_mode_eligible_learn_more_CTA'
      } else {
        this.headingText = 'Breezeline Mobile Coming Soon!'
        this.mobileParagraph =
          'Save up to $10/mo on your Breezeline service and enjoy exclusive mobile pricing.\nChoose the plan that works for each member of your family.'
        this.buttonText = 'Learn More'
        this.mobileLink = 'https://www.breezeline.com/mobile-is-coming'
        this.triggerString = 'trigger_mode_ineligible_earn_more_CTA'
      }
    },
  }
</script>

<style scoped>
  .testing {
    max-width: 149px;
    max-height: 149px;
    border-radius: 7px;
  }

  .heading-text {
    font-weight: 700;
    font-size: 16px;
    line-height: 23.31px;
  }
</style>
