<template>
  <main>
    <AppSuspense
      :dependencies="['account']"
      class="lg:row-span-2">
      <template #loading>
        <p class="animate-pulse">Loading...</p>
      </template>
      <template #error>
        <p class="text-error">Unable to load account name. Please try again later.</p>
      </template>
      <template #ready>
        <WelcomeUser
          :account-number="$store.state.account.accountNumber"
          :login-name="loginName"
          class="lg:col-span-2"></WelcomeUser>
      </template>
    </AppSuspense>

    <AppPromoBannerModal
      v-if="isAndroid() || isIOS()"
      :open="isMobileAppBannerVisible"
      @close-mobile-app-banner="closeMobileAppBanner()"></AppPromoBannerModal>
    <AppPromoBanner
      v-else
      :open="isMobileAppBannerVisible"
      class="lg:col-span-2"
      @close-mobile-app-banner="closeMobileAppBanner()"></AppPromoBanner>

    <AppSuspense
      :dependencies="['billing']"
      :class="dynamicGridClass"
      class="h-full">
      <template #loading>
        <AppPanel
          label="Account"
          class="h-full">
          <p class="animate-pulse text-center">Loading billing...</p>
        </AppPanel>
      </template>
      <template #error>
        <AppPanel
          label="Account"
          class="h-full">
          <p class="text-center text-error">Unable to load billingcount. Please try again later.</p>
        </AppPanel>
      </template>
      <template #ready>
        <MakeAPayment
          :is-summary="true"
          :amount-due="$store.state.billing.amountDue"
          :is-promise-to-pay-eligible="$store.getters['billing/isPromiseToPayEligible']"
          :has-promise-to-pay-scheduled="$store.getters['billing/hasPromiseToPayScheduled']"
          :promise-to-pay-date="$store.state.billing.promiseToPayPayments?.date"
          :promise-to-pay-amount="$store.getters['billing/getPromiseToPayAmount']"
          :days-past-due="$store.state.billing.daysPastDue"
          :is-past-due="$store.getters['billing/accountIsPastDue']"
          :due-date="$store.state.billing.dueDate"
          :is-on-autopay="$store.getters['discounts/autopayIsEnabled']"
          :had-autopay="$store.getters['billing/accountHadAutopay']"
          :autopay-draft-date="$store.state.billing.debitDate"
          :display-bill-explainer="true"
          :show-disclaimer="true"
          :expiry-notice="$store.getters['paymentMethods/getExpiringCreditCardNotice']"
          :has-pending-payment="$store.getters['billing/getPendingPaymentTotalStv']"
          @view-bill-explainer="$store.dispatch('statements/getBillExplainerUri')"
          @pay-now="payNow()"
          @promise-to-pay="promiseToPay()"
          @modify-promise-to-pay="modifyPromiseToPay()"
          @cancel-promise-to-pay="cancelPromiseToPay()"
          class="h-full"></MakeAPayment>
      </template>
    </AppSuspense>

    <AppSuspense
      :dependencies="['account']"
      class="lg:row-span-2">
      <template #loading>
        <AppPanel
          label="Account"
          class="h-full">
          <p class="animate-pulse text-center">Loading account...</p>
        </AppPanel>
      </template>
      <template #error>
        <AppPanel
          label="Account"
          class="h-full">
          <p class="text-center text-error">Unable to load account. Please try again later.</p>
        </AppPanel>
      </template>
      <template #ready>
        <AccountDetails
          v-bind="$store.state.account"
          :is-summary="true"
          :accountSignIn="true"
          class="h-full"
          @logout="$store.dispatch('logout')"
          @change-preferred-email="changePreferredEmail"></AccountDetails>
      </template>
    </AppSuspense>

    <AppSuspense
      v-if="this.$store.state.appointments.appointmentInfo.appointments.length > 0"
      :dependencies="['appointments', 'account']">
      <template #loading>
        <AppPanel
          label="Appointments"
          class="h-full">
          <p class="animate-pulse text-center">Loading appointments...</p>
        </AppPanel>
      </template>
      <template #error>
        <AppPanel
          label="Appointments"
          class="h-full">
          <p class="text-center text-error">Unable to load Appointments. Please try again later.</p>
        </AppPanel>
      </template>
      <template #ready>
        <Appointments v-bind="$store.state.account"></Appointments>
      </template>
    </AppSuspense>
    <AppSuspense :dependencies="['account']">
      <template #loading>
        <AppPanel
          label="Current Speed"
          class="h-full">
          <p class="animate-pulse text-center">Loading current speed...</p>
        </AppPanel>
      </template>
      <template #error>
        <AppPanel
          label="Current Speed"
          class="h-full">
          <p class="text-center text-error">
            Unable to load current speed. Please try again later.
          </p>
        </AppPanel>
      </template>
      <template #ready>
        <UpgradeBanner
          v-if="showUpgradeBanner && isEligibleForUpgrade"
          :is-eligible="isEligibleForUpgrade"
          :url="
            isEligibleForUpgrade
              ? $store.getters['ordergatewayUrl']
              : $store.getters['manualupgradeUrl']
          "
          :current-speed="currentSpeed"
          :ideal-for="idealFor"></UpgradeBanner>
      </template>
    </AppSuspense>

    <AppSuspense
      v-if="this.$store.state.mobile.mobileInfo.mobileDiscountQualified"
      :dependencies="['mobile']">
      <template #loading>
        <AppPanel
          label="Mobile"
          class="h-full">
          <p class="animate-pulse text-center">Loading mobile...</p>
        </AppPanel>
      </template>
      <template #error>
        <AppPanel
          label="Mobile"
          class="h-full">
          <p class="text-center text-error">Unable to load Mobile. Please try again later.</p>
        </AppPanel>
      </template>
      <template #ready>
        <MobileBanner></MobileBanner>
      </template>
    </AppSuspense>

    <AppSuspense
      :dependencies="['equipments']"
      class="lg:col-span-2">
      <template #loading>
        <AppPanel
          label="Manage & troubleshoot equipment"
          alignment="center">
          <p class="animate-pulse text-center">Loading equipments...</p>
        </AppPanel>
      </template>
      <template #error>
        <AppPanel
          label="Manage & troubleshoot equipment"
          alignment="center">
          <p class="text-center text-error">Unable to load equipments. Please try again later.</p>
        </AppPanel>
      </template>
      <template #ready>
        <EquipmentHelp
          :displayHeader="true"
          :item-list="$store.getters['equipments/getEquipmentHelp']('Dashboard')"></EquipmentHelp>
      </template>
    </AppSuspense>

    <AppSuspense
      :dependencies="['support']"
      class="lg:col-span-2">
      <template #loading>
        <AppPanel label="Support">
          <p class="animate-pulse text-center">Loading support...</p>
        </AppPanel>
      </template>
      <template #error>
        <AppPanel label="Support">
          <p class="text-center text-error">Unable to load support. Please try again later.</p>
        </AppPanel>
      </template>
      <template #ready>
        <SupportCategories
          :is-summary="true"
          class="lg:col-span-2"
          :articles-by-category="$store.getters['support/getArticleByCategory']"
          :articles-by-sub-category="$store.getters['support/getArticleBySubCategory']"
          :articles-by-title="$store.getters['support/getArticleByTitle']"></SupportCategories>
      </template>
    </AppSuspense>
  </main>
</template>

<script>
  import WelcomeUser from '../../components/WelcomeUser/index.vue'
  import UpgradeBanner from '../../components/UpgradeBanner/index.vue'
  import DiscountBanner from '../../components/DiscountBanner/index.vue'
  import MakeAPayment from '../../components/MakeAPayment/index.vue'
  import AccountDetails from '../../components/AccountDetails/index.vue'
  import EquipmentHelp from '../../components/EquipmentHelp/index.vue'
  import SupportCategories from '../../components/SupportCategories/index.vue'
  import Appointments from '../../components/Appointments/index.vue'
  import MobileBanner from '../../components/MobileBanner/index.vue'

  import speeds from '*/Speeds.js'

  import hasMobileAppBanner from '#/shared/mixins/hasMobileAppBanner.js'

  export default {
    name: 'DashboardPage',
    components: {
      WelcomeUser,
      UpgradeBanner,
      DiscountBanner,
      MakeAPayment,
      AccountDetails,
      EquipmentHelp,
      SupportCategories,
      MobileBanner,
    },
    mixins: [hasMobileAppBanner],
    computed: {
      isEligibleForUpgrade() {
        if (!this.$store.state.account.currentPlan?.name) {
          return false
        }

        return this.$store.state.account.currentPlan.isEligibleForUpgrade
      },
      currentSpeed() {
        return this.$store.state.account.currentPlan.down
      },
      idealFor() {
        if (!this.$store.state.account.currentPlan?.name) {
          return ''
        }

        const speed = speeds[this.slugify(this.$store.state.account.currentPlan.name)]

        if (!speed) {
          return ''
        }

        // console.log
        // todo: hook this up to a call or a map
        return `Ideal for: ${speed.idealFor}`
      },
      showUpgradeBanner() {
        return import.meta.env.VITE_ORDERGATEWAY === 'true'
      },
      dynamicGridClass() {
        if (!this.isEligibleForUpgrade || !this.showUpgradeBanner) {
          return 'lg:row-span-2'
        }

        return ''
      },
      loginName() {
        const firstName = this.$store.state.account.customerInfo?.firstName
        let capitalizedName = ''
        if (firstName) {
          capitalizedName = firstName.toLowerCase()
          capitalizedName = capitalizedName.replace(
            capitalizedName[0],
            capitalizedName[0].toUpperCase()
          )
        }
        return capitalizedName || 'Customer'
      },
    },
    mounted() {
      if (this.$store.state.support.articles.length === 0) {
        this.$store.dispatch('support/initialize')
      }
    },
    methods: {
      enrollNow() {
        this.$store.dispatch('setSwitch', {
          key: 'highlight-discounts',
          value: true,
        })
        this.$store.commit('LOCK_APP')

        setTimeout(() => {
          this.$router.push({ name: 'billing' })
        }, 400)
      },
      payNow() {
        this.$store.dispatch('setSwitch', {
          key: 'show-payment-wizard',
          value: true,
        })
        this.$store.commit('LOCK_APP')

        setTimeout(() => {
          this.$router.push({ name: 'billing' })
        }, 200)
      },
      promiseToPay() {
        this.$store.dispatch('setSwitch', {
          key: 'show-promise-to-pay-wizard',
          value: true,
        })
        this.$store.commit('LOCK_APP')

        setTimeout(() => {
          this.$router.push({ name: 'billing' })
        }, 200)
      },
      modifyPromiseToPay() {
        this.$store.dispatch('setSwitch', {
          key: 'modify-promise-to-pay-wizard',
          value: true,
        })
        this.$store.commit('LOCK_APP')

        setTimeout(() => {
          this.$router.push({ name: 'billing' })
        }, 200)
      },
      cancelPromiseToPay() {
        this.$store.dispatch('setSwitch', {
          key: 'cancel-promise-to-pay-wizard',
          value: true,
        })
        this.$store.commit('LOCK_APP')

        setTimeout(() => {
          this.$router.push({ name: 'billing' })
        }, 200)
      },
      changePreferredEmail(newEmail, callback) {
        this.$store.dispatch('account/changePreferredEmail', newEmail).then(callback)
      },
    },
  }
</script>

<style scoped>
  main {
    @apply lg:grid-cols-2;
  }
</style>
