<template>
  <div>
    <template v-if="dependenciesFailed">
      <slot name="error"></slot>
    </template>
    <template v-else>
      <slot
        v-if="isReady"
        name="ready"></slot>
      <slot
        v-else
        name="loading"></slot>
    </template>
  </div>
</template>

<script>
  export default {
    name: 'AppSuspense',
    props: {
      dependencies: {
        type: Array,
        required: true,
      },
    },
    data: () => ({
      //
    }),
    computed: {
      isReady() {
        let flag = true

        for (const dep of this.dependencies) {
          if (!this.$store.state.modulesStatus[dep]) {
            flag = false
            break
          }
        }

        return flag
      },
      dependenciesFailed() {
        return this.dependencies.some((dep) => this.$store.state.modulesStatus[dep] !== true)
      },
    },
  }
</script>
