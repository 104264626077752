import Vue from 'vue'
import VueRouter from 'vue-router'
import baseRoutes from '#/routes'
import store from './store/index.js'

import DashboardPage from './pages/DashboardPage/index.vue'
import BillingPage from './pages/BillingPage/index.vue'
import EquipmentPage from './pages/EquipmentPage/index.vue'
import SupportPage from './pages/SupportPage/index.vue'
import AccountPage from './pages/AccountPage/index.vue'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: '/',
  routes: [
    {
      name: 'dashboard',
      path: '/dashboard',
      components: {
        main: DashboardPage,
      },
      beforeEnter,
    },
    {
      name: 'billing',
      path: '/billing',
      components: {
        main: BillingPage,
      },
      beforeEnter,
    },
    {
      name: 'equipment',
      path: '/equipment',
      components: {
        main: EquipmentPage,
      },
      beforeEnter,
    },
    {
      name: 'support',
      path: '/support',
      components: {
        main: SupportPage,
      },
      beforeEnter,
    },
    {
      name: 'account',
      path: '/account',
      components: {
        main: AccountPage,
      },
      beforeEnter,
    },
    {
      name: 'log-out',
      path: '/log-out',
      beforeEnter() {
        // clear out the notifications/banner hidden states
        window.localStorage.clear()
        store.dispatch('logout')
      },
    },

    // maintenance and error pages
    ...baseRoutes,

    // catch-all
    {
      path: '*',
      redirect: '/dashboard',
    },
  ],
})

// smooth-scroll to top of the page
function beforeEnter(to, from, next) {
  window.scroll({
    top: 0,
    left: 0,
    behavior: 'smooth',
  })
  next()
}

router.afterEach(() => {
  const pane = document.querySelector('[data-app-pane]')
  pane?.scrollTo(0, 0)
})

if (import.meta.env.PROD) {
  router.afterEach((to) => {
    window.gtag?.('config', import.meta.env.VITE_GTM_TRACKING_ID, {
      page_path: to.path,
      page_location: String(window.location),
      app_name: import.meta.env.VITE_GA_APP_NAME,
      send_page_view: true,
      user_id: window?.marketingId ?? null,
    })
  })
}

export default router
