import gql from 'graphql-tag'
import { DateTime } from 'luxon'
const today = DateTime.local()

export default {
  namespaced: true,

  state: {
    dueDate: null, // be careful of this variable as it represents multiple types of values
    debitDate: null,
    amountDue: 0,
    pastDue: 0, // total delinquent amount
    daysPastDue: 0, // total days past due date
    allPayments: [],
    promiseToPayPayments: {
      date: null,
    },
    pendingPaymentTotalStv: null,
  },

  getters: {
    debitOrDueDate({ debitDate = null, dueDate = null }) {
      let res = debitDate

      if (dueDate !== null && dueDate !== 'AUTO PAY' && dueDate !== 'ON RECPT') {
        res = dueDate
      }

      return res
    },
    accountHadAutopay({ dueDate }) {
      return dueDate === 'AUTO PAY'
    },
    accountIsPastDue({ dueDate }) {
      return dueDate === 'ON RECPT'
    },
    getScheduledPayments({ allPayments }) {
      return allPayments.filter((p) => p?.isScheduled)
    },
    getPromiseToPayPayment(state, getters) {
      if (!getters.hasPromiseToPayScheduled) {
        return null
      }

      return getters.getScheduledPayments.find(
        (payment) => payment.isScheduled && state.promiseToPayPayments.date === payment.date
      )
    },
    isPromiseToPayEligible({ daysPastDue, promiseToPayPayments, allPayments }) {
      return !!(
        daysPastDue > 31 &&
        daysPastDue < 53 &&
        (promiseToPayPayments?.date == null ||
          !DateTime.fromISO(promiseToPayPayments?.date).startOf('day') <= today.startOf('day') ||
          (allPayments.filter((p) => p?.isScheduled).length &&
            allPayments.filter((p) => p?.isScheduled)[0]?.date !== promiseToPayPayments?.date))
      )
    },
    hasPromiseToPayScheduled({ promiseToPayPayments, allPayments }) {
      return (
        promiseToPayPayments?.date != null &&
        (!DateTime.fromISO(promiseToPayPayments?.date).startOf('day') <= today.startOf('day') ||
          allPayments.filter((p) => p?.isScheduled)[0]?.date === promiseToPayPayments?.date)
      )
    },
    getPromiseToPayAmount({ promiseToPayPayments, allPayments, pastDue }) {
      return promiseToPayPayments?.date != null && allPayments.length
        ? allPayments.find((p) => p.date === promiseToPayPayments.date)?.amount || pastDue
        : pastDue
    },
    getPendingPaymentTotalStv({ pendingPaymentTotalStv }) {
      if (typeof pendingPaymentTotalStv !== 'undefined' && pendingPaymentTotalStv !== null) {
        const dollarAmount = pendingPaymentTotalStv / 100
        return dollarAmount?.toFixed(2)
      }
      return null
    },
  },

  mutations: {
    SET_DUE_DATE(state, payload) {
      // 'AUTO PAY' || 'ON RECPT' (aka past due) || 'YYYY-MM-DD'
      state.dueDate = payload
    },
    SET_BILLING_INFO(state, payload) {
      state.amountDue = parseFloat(payload.amountDueStv) / 100
      state.debitDate = payload.debitDate || null // aka Autopay draft date
      state.pastDue = parseFloat(payload.amountDelinquentStv) / 100 || 0
      state.daysPastDue = payload.daysDelinquent || 0
    },
    SET_SCHEDULED_PAYMENTS(state, payload) {
      // note: this replaces the allPayments[]
      state.allPayments = payload
        .sort((a, b) => {
          const dateA = DateTime.fromJSDate(a.date)
          const dateB = DateTime.fromJSDate(b.date)
          return dateA > dateB ? -1 : dateA < dateB ? 1 : 0
        }) // farthest first
        .map((sp) => ({
          id: sp.scheduledPaymentId,
          date: sp.date,
          amount: parseFloat(sp.payment.paymentAmountStv) / 100,
          paymentMethodId: sp.payment.storedPaymentMethodId,
          isScheduled: true,
        }))
    },
    SET_PENDING_PAYMENT_TOTAL(state, payload) {
      state.pendingPaymentTotalStv = payload
    },
    SET_PROMISE_TO_PAY(state, payload) {
      state.promiseToPayPayments = {
        date: payload.date,
      }
    },
    SET_RECENT_PAYMENTS(state, payload) {
      // note: this concats to the allPayments[]
      const sortedPayload = payload
        .sort((a, b) => {
          const dateA = DateTime.fromJSDate(a.date)
          const dateB = DateTime.fromJSDate(b.date)
          return dateA > dateB ? -1 : dateA < dateB ? 1 : 0
        }) // most recent first

        // .sort((a, b) => (DateTime.fromISO(a.date).isBefore(DateTime.fromISO(b.date)) ? 1 : -1)) // most recent first
        .slice(0, import.meta.env.VITE_MAX_RECENT_PAYMENTS_TO_SHOW || 6)

      state.allPayments = state.allPayments.concat(
        sortedPayload.map((rp) =>
          Object.assign(
            {
              date: rp.date,
              amount: parseFloat(rp.paymentAmountStv) / 100,
              isRecent: true,
            },
            {
              CARD: {
                paymentMethod: {
                  paymentType: rp.paymentMethod?.paymentType,
                  displayName: 'Credit/Debit Card',
                  lastFour: rp.paymentMethod?.cardPaymentInfo?.cardNumber,
                },
              },
              EFT: {
                paymentMethod: {
                  paymentType: rp.paymentMethod?.paymentType,
                  displayName: 'Checkings/Savings Account',
                  accountNumber: rp.paymentMethod?.eftPaymentInfo?.eftAccountNumber,
                },
              },
            }[rp.paymentMethod?.paymentType] || {
              paymentMethod: {
                paymentType: null,
              },
            }
          )
        )
      )
    },
  },

  actions: {
    initialize({ dispatch }) {
      return Promise.all([
        dispatch('_getDueDate'),
        dispatch('_getBillingInfo'),
        dispatch('_getScheduledPayments').then(() => dispatch('_getRecentPayments')),
        dispatch('_getPendingPaymentTotal'),
        dispatch('_getPromiseToPay'),
        // dispatch('_testEndpoint'),
      ])
        .then((all) => {
          // note: this applies for this whole module. if further specificity is required, we need to adjust the modulesStatus to have a deeper scope.
          dispatch('moduleIsReady', { name: 'billing', result: true }, { root: true })
          return all
        })
        .catch((e) => {
          console.error(e)
          dispatch(
            'moduleIsReady',
            { name: 'billing', result: { error: e } },
            { root: { error: e } }
          )
        })
    },
    _getDueDate({ commit }) {
      return new Promise((resolve, reject) => {
        window.$apollo.addSmartQuery('getDueDate', {
          query: gql`
            query getDueDate {
              getDueDate
            }
          `,
          // possible bug below: if data returned is falsy (0), it will fail
          result: (r) => r.data?.getDueDate && resolve(commit('SET_DUE_DATE', r.data.getDueDate)),
          error: (e) => reject(e),
        })
      })
    },
    _getBillingInfo({ commit }) {
      return new Promise((resolve, reject) => {
        window.$apollo.addSmartQuery('getBillingInfo', {
          query: gql`
            query getBillingInfo {
              getBillingInfo {
                amountDelinquentStv
                amountDueStv
                cycleDay
                daysDelinquent
                dueDate
                debitDate
                projectedBalanceStv
                responsibleParty
              }
            }
          `,
          result: (r) =>
            r.data?.getBillingInfo && resolve(commit('SET_BILLING_INFO', r.data.getBillingInfo)),
          error: (e) => reject(e),
        })
      })
    },
    // note: not sure why we're not receiving the full payment info here...
    _getScheduledPayments({ commit }) {
      return new Promise((resolve, reject) => {
        window.$apollo.addSmartQuery('getScheduledPayment', {
          query: gql`
            query getScheduledPayment {
              getScheduledPayment {
                scheduledPaymentId
                date
                payment {
                  storedPaymentMethodId
                  paymentAmountStv
                }
              }
            }
          `,
          result: (r) =>
            r.data?.getScheduledPayment &&
            resolve(commit('SET_SCHEDULED_PAYMENTS', r.data.getScheduledPayment)),
          error: (e) => reject(e),
        })
      })
    },
    _getPendingPaymentTotal({ commit }) {
      return new Promise((resolve, reject) => {
        window.$apollo.addSmartQuery('getPendingPaymentTotal', {
          query: gql`
            query getPendingPaymentTotal {
              getPendingPaymentTotal {
                pendingPaymentTotalStv
              }
            }
          `,
          result: (r) => {
            if (r.data?.getPendingPaymentTotal) {
              commit(
                'SET_PENDING_PAYMENT_TOTAL',
                r.data?.getPendingPaymentTotal.pendingPaymentTotalStv
              )
              resolve()
            }
          },
          error: (e) => reject(e),
        })
      })
    },
    _getPromiseToPay({ commit }) {
      return new Promise((resolve, reject) => {
        window.$apollo.addSmartQuery('getPromiseToPay', {
          query: gql`
            query getPromiseToPay {
              getPromiseToPay {
                date
              }
            }
          `,
          result: (r) =>
            r.data?.getPromiseToPay &&
            resolve(commit('SET_PROMISE_TO_PAY', r.data.getPromiseToPay)),
          error: (e) => reject(e),
        })
      })
    },
    _getRecentPayments({ commit }) {
      return new Promise((resolve, reject) => {
        window.$apollo.addSmartQuery('getRecentPayment', {
          query: gql`
            query getRecentPayment {
              getRecentPayment {
                date
                paymentAmountStv
                paymentMethod {
                  id
                  nickname
                  paymentType
                  default
                  cardPaymentInfo {
                    cardNumber
                    expiryMonth
                    expiryYear
                    firstName
                    lastName
                  }
                  eftPaymentInfo {
                    eftAccountNumber
                    eftAccountType # enum
                    firstName
                    lastName
                    routingNumber
                  }
                }
              }
            }
          `,
          result: (r) =>
            r.data?.getRecentPayment &&
            resolve(commit('SET_RECENT_PAYMENTS', r.data.getRecentPayment)),
          error: (e) => reject(e),
        })
      })
    },
    refetchAll({ commit, dispatch }) {
      commit('LOCK_APP', null, { root: true })

      return Promise.all([
        window.$apollo.queries.getDueDate.refetch(),
        window.$apollo.queries.getBillingInfo.refetch(),
        window.$apollo.queries.getPendingPaymentTotal.refetch(),
        window.$apollo.queries.getScheduledPayment
          .refetch()
          .then(() => window.$apollo.queries.getRecentPayment.refetch()),
        window.$apollo.queries.getPromiseToPay.refresh(),
      ])
        .catch((e) => {
          console.error(e)
          return dispatch(
            'alerts/setAlert',
            {
              message: 'Error fetching billing. Please contact Support.',
            },
            { root: true }
          )
        })
        .finally(() => {
          commit('UNLOCK_APP', null, { root: true })
        })
    },
    // Only for regular or scheduled payments, not OTPs
    submitPayment({ commit, dispatch }, { paymentAmount, paymentDate, paymentMethod }) {
      commit('LOCK_APP', null, { root: true })

      // todo: this might be buggy
      return window.$apollo
        .mutate({
          mutation: gql`
            mutation createPayment($date: Date, $paymentInfo: PaymentInput!) {
              createPayment(date: $date, paymentInfo: $paymentInfo) {
                message
              }
            }
          `,
          variables: {
            date: paymentDate, // should already be in 'ISO8601' or 'YYYY-MM-DD'
            paymentInfo: {
              paymentAmountStv: Math.round(paymentAmount * 100), // Math.round() resolves the issue of js decimal multiplication and trailing numbers e.g. 19.989999999998 rather than 19.99
              paymentMethod: {
                storedPaymentId: paymentMethod.id,
              },
              storedPaymentMethodType: paymentMethod.paymentType,
            },
          },
        })
        .then((r) => {
          dispatch('refetchAll')
          return [
            'EFT payment created',
            'Card payment created',
            'Future payment scheduled',
          ].includes(r.data.createPayment.message)
        })
        .catch((e) => {
          console.error(e)
          return dispatch(
            'alerts/setAlert',
            {
              message: 'Unable to submit payment. Please contact Support.',
            },
            { root: true }
          )
        })
        .finally(() => {
          commit('UNLOCK_APP', null, { root: true })
        })
    },
    _testEndpoint() {
      return new Promise((resolve, reject) => {
        setTimeout(() => {
          resolve(true)
        }, 5000)
      })
    },
    createPromiseToPay({ commit, dispatch }, { paymentDate }) {
      return window.$apollo
        .mutate({
          mutation: gql`
            mutation createPromiseToPay($scheduledPayment: ScheduledPaymentInput!) {
              createPromiseToPay(scheduledPayment: $scheduledPayment) {
                message
              }
            }
          `,
          variables: {
            scheduledPayment: {
              date: paymentDate, // should already be in 'ISO8601' or 'YYYY-MM-DD'
            },
          },
        })
        .then((r) => {
          dispatch('refetchAll')
          return [
            'EFT payment created',
            'Card payment created',
            'Future payment scheduled',
            'Successfully added promise',
          ].includes(r.data.createPromiseToPay.message)
        })
        .catch((e) => {
          console.error(e)
        })
        .finally(() => {
          commit('UNLOCK_APP', null, { root: true })
        })
    },

    submitPromiseToPay({ commit, dispatch }, { paymentAmount, paymentDate, paymentMethod }) {
      commit('LOCK_APP', null, { root: true })

      dispatch('submitPayment', { paymentAmount, paymentDate, paymentMethod })

      return dispatch('createPromiseToPay', { paymentDate })
    },
    convertToPromiseToPay(
      { state, commit, dispatch },
      { paymentAmount, paymentDate, paymentMethod, scheduleToPromise }
    ) {
      commit('LOCK_APP', null, { root: true })

      const payment = state.allPayments.find(
        (sp) => sp?.isScheduled && sp?.date === scheduleToPromise?.scheduledDate
      )
      paymentDate = scheduleToPromise?.dateInRange ? payment?.date : paymentDate
      paymentAmount = scheduleToPromise?.priceInRange ? payment?.amount : paymentAmount

      if (!scheduleToPromise?.dateInRange || !scheduleToPromise?.priceInRange) {
        dispatch('cancelScheduledPayment', payment)
        dispatch('submitPayment', { paymentAmount, paymentDate, paymentMethod })
      }

      return dispatch('createPromiseToPay', { paymentDate })
    },
    cancelScheduledPayment({ commit, state }, payment) {
      commit('LOCK_APP', null, { root: true })

      if (state.promiseToPayPayments?.date === payment?.date) {
        window.$apollo
          .mutate({
            mutation: gql`
              mutation deletePromiseToPay {
                deletePromiseToPay {
                  message
                }
              }
            `,
          })
          .then((r) => {
            if (r.errors?.length) {
              throw new Error(r.errors[0].message)
            }
          })
          .catch((e) => {
            console.error(e)

            return false
          })
      }

      return !!window.$apollo
        .mutate({
          mutation: gql`
            mutation deleteFuturePayment($scheduledPaymentId: String) {
              deleteFuturePayment(scheduledPaymentId: $scheduledPaymentId) {
                message
              }
            }
          `,
          variables: {
            scheduledPaymentId: payment.id,
          },
        })
        .then((r) => {
          if (r.errors?.length) {
            throw new Error(r.errors[0].message)
          }

          return Promise.all([
            window.$apollo.queries.getScheduledPayment
              .refetch()
              .then(() => window.$apollo.queries.getRecentPayment.refetch()),
            window.$apollo.queries.getPromiseToPay.refresh(),
          ])
        })
        .catch((e) => {
          console.error(e)

          return false
        })
        .finally(() => {
          commit('UNLOCK_APP', null, { root: true })
        })
    },
    cancelScheduledPaymentsByPaymentMethod({ state }, paymentMethod) {
      return Promise.all(
        state.allPayments
          .filter((sp) => {
            return sp?.isScheduled && sp?.paymentMethodId === paymentMethod.id
          })
          .map((sp) => {
            if (state.promiseToPayPayments?.date === sp?.date) {
              window.$apollo
                .mutate({
                  mutation: gql`
                    mutation deletePromiseToPay {
                      deletePromiseToPay {
                        message
                      }
                    }
                  `,
                })
                .then((r) => {
                  if (r.errors?.length) {
                    throw new Error(r.errors[0].message)
                  }
                })
                .catch((e) => {
                  console.error(e)
                })
            }

            return window.$apollo.mutate({
              mutation: gql`
                mutation deleteFuturePayment($scheduledPaymentId: String) {
                  deleteFuturePayment(scheduledPaymentId: $scheduledPaymentId) {
                    message
                  }
                }
              `,
              variables: {
                scheduledPaymentId: sp.id,
              },
            })
          })
      )
    },
    cancelPromiseToPay({ state, dispatch }) {
      const payment = state.allPayments.find(
        (sp) => sp?.isScheduled && sp?.date === state.promiseToPayPayments?.date
      )

      if (payment) {
        return dispatch('cancelScheduledPayment', payment)
      } else {
        window.$apollo
          .mutate({
            mutation: gql`
              mutation deletePromiseToPay {
                deletePromiseToPay {
                  message
                }
              }
            `,
          })
          .then((r) => {
            if (r.errors?.length) {
              throw new Error(r.errors[0].message)
            }
          })
          .catch((e) => {
            console.error(e)

            return false
          })
      }
    },
  },
}
