import axios from 'axios'

export default {
  namespaced: true,

  state: {
    articles: [],
  },

  getters: {
    getArticleByCategory: (state) => (category) => {
      return state.articles.filter((a) => a.category === category)
    },
    getArticleBySubCategory: (state) => (subCategory) => {
      return state.articles.filter((a) => a.subCategory === subCategory)
    },
    getArticleByTitle: (state) => (title) => {
      return state.articles.find((a) => a.title === title)
    },
  },

  mutations: {
    SET_ARTICLES(state, articles) {
      state.articles = articles
    },
  },

  actions: {
    initialize({ dispatch }) {
      return Promise.all([
        dispatch('_getSupportArticles'),
        // dispatch('_testEndpoint')
      ])
        .then((all) => {
          // note: this applies for this whole module. if further specificity is required, we need to adjust the modulesStatus to have a deeper scope.
          dispatch('moduleIsReady', { name: 'support', result: true }, { root: true })
          return all
        })
        .catch((e) => {
          console.error(e)
          dispatch(
            'moduleIsReady',
            { name: 'support', result: { error: e } },
            { root: { error: e } }
          )
        })
    },
    _testEndpoint() {
      return new Promise((resolve, reject) => {
        setTimeout(() => {
          resolve(true)
        }, 5000)
      })
    },
    _getSupportArticles({ commit }) {
      return axios
        .get(import.meta.env.VITE_BREEZELINE_URL + '/supportContent.json')
        .then((response) => {
          commit('SET_ARTICLES', response.data.data)
        })
        .catch((e) => {
          console.error(e)
        })
    },
  },
}
