import gql from 'graphql-tag'

export default {
  namespaced: true,

  state: {
    autopay: {
      isAvailable: true,
      paymentMethod: null, // structure varies, see mutation
    },
    ecosave: {
      isEnabled: false,
    },
  },

  getters: {
    autopayIsEnabled({ autopay }) {
      return !!autopay.paymentMethod
    },
    discountsAreAvailable({ autopay, ecosave }) {
      return (autopay.isAvailable && autopay.paymentMethod === null) || !ecosave.isEnabled
    },
    discountsAreEnabled({ autopay, ecosave }) {
      return autopay.isAvailable && autopay.paymentMethod !== null && ecosave.isEnabled
    },
  },

  mutations: {
    SET_AUTOPAY_PAYMENT_METHOD({ autopay }, payload) {
      if (payload.length > 0) {
        const pm = payload[0]

        // Possible bug: only CARD and EFT are accounted for
        if (pm.paymentType === 'CARD') {
          autopay.paymentMethod = {
            id: pm.id,
            paymentType: pm.paymentType,
            nickname: pm.nickname,
            displayName: 'Credit/Debit Card',
            default: pm.default,
            accountHolder: `${pm.cardPaymentInfo.firstName} ${pm.cardPaymentInfo.lastName}`,
            lastFour: pm.cardPaymentInfo.cardNumber,
            expiresOn: `${pm.cardPaymentInfo.expiryMonth}/${pm.cardPaymentInfo.expiryYear}`,
          }
        } else if (pm.paymentType === 'EFT') {
          autopay.paymentMethod = {
            id: pm.id,
            paymentType: pm.paymentType,
            nickname: pm.nickname,
            displayName:
              pm.eftPaymentInfo.eftAccountType === 'CHECKING'
                ? 'Checking Account'
                : 'Savings Account',
            default: pm.default,
            accountHolder: `${pm.eftPaymentInfo.firstName} ${pm.eftPaymentInfo.lastName}`,
            accountNumber: pm.eftPaymentInfo.eftAccountNumber,
            routingNumber: pm.eftPaymentInfo.routingNumber,
            accountType: pm.eftPaymentInfo.eftAccountType,
          }
        } else {
          // todo: redirect to 500?
          console.log('Unknown payment type for Autopay')
        }
      } else {
        autopay.paymentMethod = null
      }
    },
    SET_ECOSAVE_STATUS({ ecosave }, payload) {
      // Possible bug: Any other response will result in "Ecosave is not active"
      ecosave.isEnabled = payload.message === 'Ebill active'
    },
  },

  actions: {
    initialize({ dispatch }) {
      return Promise.all([
        dispatch('_getAutopay'),
        dispatch('_getEbill'), // aka Ecosave
      ])
    },
    _getAutopay({ commit }) {
      return new Promise((resolve, reject) => {
        window.$apollo.addSmartQuery('getAutopay', {
          query: gql`
            query getAutopay {
              getAutopay {
                cardPaymentInfo {
                  cardNumber
                  expiryMonth
                  expiryYear
                  firstName
                  lastName
                }
                eftPaymentInfo {
                  eftAccountNumber
                  eftAccountType # enum
                  firstName
                  lastName
                  routingNumber
                }
                id
                nickname
                paymentType # enum
                default
              }
            }
          `,
          result: (r) =>
            r.data?.getAutopay && resolve(commit('SET_AUTOPAY_PAYMENT_METHOD', r.data.getAutopay)),
          error: (e) => reject(e),
        })
      })
    },
    _getEbill({ commit }) {
      // aka Ecosave
      return new Promise((resolve, reject) => {
        window.$apollo.addSmartQuery('getEbill', {
          query: gql`
            query getEbill {
              getEbill {
                message
              }
            }
          `,
          result: (r) => r.data?.getEbill && resolve(commit('SET_ECOSAVE_STATUS', r.data.getEbill)),
          error: (e) => reject(e),
        })
      })
    },
    enableAutopay({ commit }, paymentMethod) {
      commit('LOCK_APP', null, { root: true })

      return window.$apollo
        .mutate({
          mutation: gql`
            mutation createAutopay($paymentMethod: PaymentMethodInput!) {
              createAutopay(paymentMethod: $paymentMethod) {
                message
              }
            }
          `,
          variables: {
            paymentMethod: { storedPaymentId: paymentMethod.id },
          },
        })
        .then((r) => {
          if (r.data.createAutopay.message.toLowerCase() === 'autopay created') {
            return window.$apollo.queries.getAutopay.refetch()
          } else {
            throw new Error('Unable to enroll in AutoPay. Please contact Support.')
          }
        })
        .catch((e) => {
          console.error(e)
        })
        .finally(() => {
          commit('UNLOCK_APP', null, { root: true })
        })
    },
    disableAutopay({ commit }) {
      commit('LOCK_APP', null, { root: true })

      return window.$apollo
        .mutate({
          mutation: gql`
            mutation deleteAutopay {
              deleteAutopay {
                message
              }
            }
          `,
        })
        .then((r) => {
          if (r.data.deleteAutopay.message.toLowerCase() === 'autopay deleted') {
            // todo: refetch payment methods too
            return window.$apollo.queries.getAutopay.refetch()
          } else {
            throw new Error('Unable to un-enroll from AutoPay. Please contact Support.')
          }
        })
        .catch((e) => {
          console.error(e)
        })
        .finally(() => {
          commit('UNLOCK_APP', null, { root: true })
        })
    },
    enableEcosave({ commit }) {
      commit('LOCK_APP', null, { root: true })

      return window.$apollo
        .mutate({
          mutation: gql`
            mutation createEbill($optIn: Boolean) {
              createEbill(optIn: $optIn) {
                message
              }
            }
          `,
          variables: {
            optIn: true,
          },
        })
        .then((r) => {
          if (r.data.createEbill.message === 'Opt-in successful') {
            return window.$apollo.queries.getEbill.refetch()
          } else {
            throw new Error('Unable to enroll in Ecosave. Please contact Support.')
          }
        })
        .catch((e) => {
          console.error(e)
        })
        .finally(() => {
          commit('UNLOCK_APP', null, { root: true })
        })
    },
    disableEcosave({ commit }) {
      commit('LOCK_APP', null, { root: true })

      return window.$apollo
        .mutate({
          mutation: gql`
            mutation createEbill($optIn: Boolean) {
              createEbill(optIn: $optIn) {
                message
              }
            }
          `,
          variables: {
            optIn: false,
          },
        })
        .then((r) => {
          if (r.data.createEbill.message === 'Opt-out successful') {
            return window.$apollo.queries.getEbill.refetch()
          } else {
            throw new Error('Unable to enroll in Ecosave. Please contact Support.')
          }
        })
        .catch((e) => {
          console.error(e)
        })
        .finally(() => {
          commit('UNLOCK_APP', null, { root: true })
        })
    },
  },
}
