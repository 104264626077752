<template>
  <Step
    :heading="heading"
    :subheading="subheading">
    <hr class="border-gray" />
    <Substep label="Select the amount you will pay*">
      <AppRadioPanel
        :is-selected="isSelected('paymentAmount', 'pastDue')"
        @click.native="select('paymentAmount', 'pastDue')">
        <template #label>
          <span class="text-lg font-bold">
            Pay your past due balance of ${{ pastDue.toFixed(2) }}
          </span>
        </template>
      </AppRadioPanel>
      <AppRadioPanel
        :is-selected="isSelected('paymentAmount', 'amountDue')"
        @click.native="select('paymentAmount', 'amountDue')">
        <template #label>
          <span class="text-lg font-bold">
            Pay your full balance of ${{ amountDue.toFixed(2) }}
          </span>
        </template>
      </AppRadioPanel>
    </Substep>
    <hr class="border-gray" />
    <Substep label="How will you pay*">
      <AppRadioPanel
        v-for="pm in paymentMethods"
        :key="pm.id"
        :is-payment-method="true"
        :payment-method="pm"
        :is-selected="isSelected('paymentMethod', pm)"
        @click.native="select('paymentMethod', pm)"></AppRadioPanel>
      <div
        v-if="paymentMethods.length < maxPaymentMethods"
        :autopay-payment-method="$store.state.discounts.autopay.paymentMethod"
        class="flex items-center gap-4 rounded-md border border-gray p-4 font-bold">
        <BzlIcon
          :customIconName="AddPaymentIcon.name"
          :src="AddPaymentIcon.src"></BzlIcon>
        <span class="hover:text-salmon">
          <a
            href="#"
            class="text-lg"
            @click.prevent="
              $emit('add-payment-method', {
                paymentAmount: (values.paymentAmount ?? 0).toFixed(2) || pastDue.toFixed(2),
                paymentDate: values.paymentDate,
              })
            ">
            New payment method
          </a>
        </span>
      </div>
    </Substep>
    <hr class="border-gray" />
    <Substep label="Payment date">
      <AppPanel
        class="flex items-center gap-4 overflow-visible rounded-md border border-gray-dark p-4 font-bold"
        padding="tight">
        <vc-date-picker
          v-model="values.paymentDate"
          class="inline-block h-full w-full"
          :available-dates="{ start: minDate, end: maxDate }"
          popover-direction="top"
          mode="date"
          @click.native="select('paymentDate', 'scheduled')">
          <template #default="{ inputValue, togglePopover }">
            <div
              class="flex items-center justify-between"
              @click="togglePopover()">
              <div>
                <button
                  type="button"
                  class="bg-blue-100 hover:bg-blue-200 text-blue-600 p-2"></button>
                <input
                  readonly
                  :value="inputValue"
                  placeholder="MM/DD/YYY"
                  class="w-24 text-center outline-none" />
              </div>
              <BzlIcon
                :customIconName="CalendarIcon.name"
                :src="CalendarIcon.src"
                style="font-size: 1.25rem"></BzlIcon>
            </div>
          </template>
        </vc-date-picker>
      </AppPanel>
    </Substep>
    <template #footer>
      <BzlButton
        :m-full="true"
        size="large"
        :disabled="!values.paymentDate || !selections.paymentMethod"
        @click="selections.paymentMethod ? proceed() : null">
        Continue
      </BzlButton>
    </template>
  </Step>
</template>

<script>
  import Step from './step.vue'
  import Substep from './substep.vue'
  import AppPanel from '../AppPanel/index.vue'
  import { DateTime } from 'luxon'
  import AddPaymentIcon from './plus-icon.svg'
  import CalendarIcon from './calendar-icon.svg'

  export default {
    name: 'PromiseToPayStep1',
    components: {
      AppPanel,
      Step,
      Substep,
    },
    props: {
      pastDue: {
        // the past due amount, typically lower than the full account balance
        type: Number,
        default: 0,
      },
      amountDue: {
        // the Full account balance
        type: Number,
        default: 0,
      },
      daysPastDue: {
        type: Number, // The number of days past the due date 62
        default: 31,
      },
      dueDate: {
        type: String, // "YYYY-MM-DD"
        default: null,
      },
      isPromiseToPayEligible: {
        type: Boolean,
        default: false,
      },
      hasPromiseToPayScheduled: {
        type: Boolean,
        default: false,
      },
      promiseToPayDate: {
        type: String, // "YYYY-MM-DD"
        default: '',
      },
      promiseToPayAmount: {
        type: Number,
        default: 0,
      },
      windowType: {
        type: String,
        default: '',
      },
      paymentMethods: {
        type: Array,
        default: () => [],
      },
      maxPaymentMethods: {
        type: Number,
        default: 4,
      },
      scheduledPayments: {
        type: Array,
        default: () => [],
      },
      paymentDetails: {
        type: Object,
        default: () => ({}),
      },
    },
    data: () => ({
      maxDate: null,
      minDate: null,
      selections: {
        paymentAmount: null, // 'amountDue' or 'customAmount'
        paymentDate: null, // 'today' or 'due' or 'scheduled'
        paymentMethod: null, // will default to defaultPM on mount, if it exists
      },
      values: {
        paymentAmount: null,
        paymentDate: null,
        // no paymentMethod (selections.paymentMethod is already the value!)
      },
      errors: {
        paymentAmount: false,
      },
      AddPaymentIcon: {
        src: AddPaymentIcon,
        name: 'Add Payment Method',
      },
      CalendarIcon: {
        src: CalendarIcon,
        name: 'Calendar',
      },
    }),
    computed: {
      heading() {
        return this.windowType === 'modify' ? 'Modify Promise to Pay' : 'Promise to Pay'
      },
      subheading() {
        return (
          'By setting up a Promise to Pay, you are committing to making a full payment on your past due balance on a specific date. ' +
          (this.scheduledPayments.length && !this.hasPromiseToPayScheduled
            ? `You have a payment scheduled for ${this.toFriendlyDate(
                this.scheduledPayments[0]?.date,
                'short-numeric-slash'
              )} to pay $${this.scheduledPayments[0]?.amount}, ` +
              (this.scheduledAmountInRange
                ? 'which does not meet the Promise to Pay requirement. Please select a new date below.'
                : this.scheduledDateInRange
                ? 'which does not cover your total past due balance. To meet the Promise to Pay requirement please select the past due balance or full account balance below.'
                : 'which does not meet the Promise to Pay requirement. Please select the past due balance or full account balance as well as a new date below.')
            : '')
        )
      },
      scheduledDateInRange() {
        // prettier-ignore
        return (this.scheduledPayments.length && 21 - (this.daysPastDue >= 33 ? 21 - (53 - this.daysPastDue) : 0) - this.howManyDaysFromToday(this.scheduledPayments[0]?.date) >= 0)
      },
      scheduledAmountInRange() {
        // prettier-ignore
        return this.scheduledPayments.length && this.scheduledPayments[0]?.amount >= this.pastDue
      },
      isScheduledPayment() {
        return this.selections.paymentDate === 'scheduled'
      },
      hasExpired() {
        const expirationDate = this.monthsFromDate(
          1,
          this.selections.paymentMethod.expiresOn,
          'MM/yyyy'
        )
        const paymentDate =
          this.values.paymentDate && DateTime.fromJSDate(this.values.paymentDate).startOf('day')

        if (expirationDate && paymentDate) {
          return expirationDate < paymentDate
        }

        return false
      },
    },

    mounted() {
      // default selections
      if (this.hasPromiseToPayScheduled) {
        this.selections.paymentAmount =
          this.pastDue.toFixed(2) === this.promiseToPayAmount.toFixed(2) ? 'pastDue' : 'amountDue'
        this.values.paymentDate = DateTime.fromISO(this.promiseToPayDate).toJSDate()
      } else {
        this.selections.paymentAmount =
          (this.paymentDetails?.paymentAmount || 0) > this.pastDue ? 'amountDue' : 'pastDue'
        this.values.paymentDate = this.paymentDetails?.paymentDate
          ? DateTime.fromISO(this.paymentDetails?.paymentDate).toJSDate() || null
          : null
      }

      /*
        To be eligible for Promise to Pay, you must be between 31 and 53 days past due.
        We will allow you to schedule a payment up to 21 days in the future.
        If you are more than 33 days past due, the available days will be less than 21 to schedule a payment.
        If you are more than 53 days past due, you will not be eligible for Promise to Pay.
       */

      this.selections.paymentDate = 'scheduled'
      this.selections.paymentMethod = this.paymentMethods.find((pm) => pm.isDefault) ?? null
      this.minDate = this.daysFromToday(1)
      // prettier-ignore
      this.maxDate = this.daysFromToday(21 - (this.daysPastDue >= 33 ? 21 - (53 - this.daysPastDue) : 0))

      if (
        this.scheduledDateInRange &&
        this.scheduledAmountInRange &&
        !this.hasPromiseToPayScheduled
      ) {
        this.proceed()
      }
    },
    methods: {
      isSelected(key, value) {
        return this.selections[key] === value
      },
      select(key, value) {
        // for selections, not values
        this.selections[key] = value

        if (key === 'paymentAmount') {
          if (value === 'amountDue') this.trackEvent('trigger_pay_full_amount_clicked')
          if (value === 'customAmount') this.trackEvent('trigger_pay_partial_amount_clicked')
        }

        if (key === 'paymentDate') {
          if (value === 'scheduled') this.trackEvent('trigger_date_selected')
        }

        if (key === 'paymentMethod') {
          this.trackEvent('trigger_payment_method_option_clicked')
        }
      },
      proceed() {
        // Reset errors
        this.errors.paymentAmount = false

        const submitted = {
          paymentAmount: this.pastDue.toFixed(2), // forces the payment to a fixed amount of 2 decimal places and fixes any conversion issues
          paymentDate: this.toFriendlyDate(this.values.paymentDate, 'ISO8601'),
          paymentMethod: this.selections.paymentMethod,
          paymentType: 'past due',
          hasPromiseToPayScheduled: this.hasPromiseToPayScheduled,
          scheduledPayments: this.scheduledPayments,
          minimumAmount: Number(this.pastDue.toFixed(2)), // forces the payment to a fixed amount of 2 decimal places and fixes any conversion issues
          daysPastDue: this.daysPastDue,
        }

        if (this.selections.paymentAmount === 'amountDue') {
          submitted.paymentAmount = this.amountDue.toFixed(2) // forces the payment to a fixed amount of 2 decimal places and fixes any conversion issues
          submitted.paymentType = 'full'
        }

        if (isNaN(submitted.paymentAmount) || submitted.paymentAmount <= 0) {
          this.errors.paymentAmount = true
          this.$store.dispatch(
            'alerts/setAlert',
            {
              message: 'Error: Please enter a valid payment amount.',
            },
            { root: true }
          )
        } else if (this.hasExpired) {
          this.$store.dispatch(
            'alerts/setAlert',
            {
              message: `Error: Unable to schedule promise to pay. Please check your credit/debit card's expiration date.`,
            },
            { root: true }
          )
        } else if (
          submitted.isFuture &&
          this.scheduledPayments.length >= (import.meta.env.VITE_MAX_SCHEDULED_PAYMENTS ?? 1) &&
          (!this.isPromiseToPayEligible || this.isTodayOrBefore(this.scheduledPayments[0]?.date))
        ) {
          this.$store.dispatch(
            'alerts/setAlert',
            {
              message: 'Error: You can only schedule up to one payment at a time.',
            },
            { root: true }
          )
        } else {
          this.$emit('proceed', submitted)
        }
      },
    },
  }
</script>
