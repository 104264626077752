<template>
  <DiscountItem
    discount-name="Ecosave"
    :available="true"
    :enabled="enabled"
    v-on="$listeners">
    <template #button-enabled>
      <div>
        <span class="text-sm font-bold">Ecosave On</span>
      </div>
    </template>
    <template #button-disabled>
      <div>
        <span class="text-sm font-bold">Ecosave Off</span>
      </div>
    </template>
    <template #button-unavailable>
      <div>
        <span class="text-sm font-bold">Unavailable</span>
      </div>
    </template>
    <template #description>
      Reduce environmental burden by receiving paperless statements. We will send your preferred
      email address a notification when your statement is ready to be viewed.
    </template>
    <template #status-enabled>
      You have Ecosave enabled! Your monthly statement notification will be sent to
      <strong>{{ email }}</strong>
    </template>
    <template #status-disabled>
      <i class="fas fa-info-circle"></i>
      You are not enrolled in Ecosave. An additional $2 fee will be added to your statement each
      month.
    </template>
    <template #status-unavailable>
      <i class="fas fa-info-circle"></i>
      Ecosave is not available in your location. Please contact Support for more information.
    </template>
  </DiscountItem>
</template>

<script>
  import DiscountItem from '../Discounts/item.vue'

  export default {
    name: 'Ecosave',
    components: {
      DiscountItem,
    },
    props: {
      enabled: {
        type: Boolean,
        default: false,
      },
      email: {
        type: String,
        default: null,
      },
    },
  }
</script>
