import gql from 'graphql-tag'

export default {
  namespaced: true,

  state: {
    loyaltyInfo: {
      availability: null,
      enrolled: null,
      enrollmentDate: null,
    },
  },

  getters: {},
  mutations: {
    SET_LOYALTY_INFO({ loyaltyInfo }, payload) {
      loyaltyInfo = Object.assign(loyaltyInfo, payload)
      delete loyaltyInfo.__typename
    },
  },
  actions: {
    initialize({ dispatch }) {
      return Promise.all([dispatch('_getLoyaltyInfo')])
        .then((all) => {
          dispatch('moduleIsReady', { name: 'customerPrograms', result: true }, { root: true })
          return all
        })
        .catch((e) => {
          console.error(e)
          dispatch(
            'moduleIsReady',
            { name: 'customerPrograms', result: { error: e } },
            { root: { error: e } }
          )
        })
    },
    _getLoyaltyInfo({ commit }) {
      return new Promise((resolve, reject) => {
        window.$apollo.addSmartQuery('getLoyaltyStatus', {
          query: gql`
            query loyalty {
              getLoyaltyStatus {
                availability {
                  available
                  reason
                }
                enrolled
                enrollmentDate
              }
            }
          `,
          result: (r) =>
            r.data?.getLoyaltyStatus &&
            resolve(commit('SET_LOYALTY_INFO', r.data.getLoyaltyStatus)),
          error: (e) => reject(e),
        })
      }).catch((e) => {
        //
      })
    },
    updateLoyaltyStatus({ commit, dispatch }, action) {
      return window.$apollo
        .mutate({
          mutation: gql`
            mutation updateLoyaltyStatus($action: EnrollAction) {
              updateLoyaltyStatus(action: $action) {
                availability {
                  available
                  reason
                }
                enrolled
                enrollmentDate
              }
            }
          `,
          variables: {
            action: action,
          },
        })
        .then((r) => {
          if (r.errors) throw r.errors

          // Note: this is not the same as re-dispatching _getCustomerInfo()
          return window.$apollo.queries.getLoyaltyStatus.refetch()
        })
        .catch((e) => {
          console.error(e)

          return dispatch(
            'alerts/setAlert',
            {
              message: 'System error. Please try again later, or contact support.',
            },
            { root: true }
          )
        })
    },
  },
}
