<template>
  <div>
    <AppPanel
      :collapsible="true"
      variant="alternate"
      label="Payment History"
      @sl-show="handleDetailsToggle">
      <div
        v-if="payments.length > 0"
        class="grid grid-cols-3 gap-4">
        <div class="font-bold">Payment Date</div>
        <div class="font-bold">Status</div>
        <div class="justify-self-end font-bold">Amount</div>
        <hr class="col-span-3" />
        <div class="col-span-3 grid gap-4">
          <div
            v-for="payment in payments"
            :key="payment.id">
            <div class="grid grid-cols-3 gap-4">
              <div>
                <span
                  class="cursor-pointer underline hover:text-salmon"
                  @click="
                    $emit('view', payment), trackEvent('trigger_view_scheduled_payments_clicked')
                  ">
                  {{ toFriendlyDate(payment.date, 'short-numeric-slash') }}
                </span>
              </div>
              <div>
                {{
                  payment.isScheduled
                    ? isPromiseToPay(payment.date)
                      ? 'Promise To Pay'
                      : 'Scheduled'
                    : payment.isRecent
                    ? 'Successful Transaction (Scheduled Payment)'
                    : '?'
                }}
              </div>
              <div class="justify-self-end">&hyphen;${{ Math.abs(payment.amount).toFixed(2) }}</div>
            </div>
          </div>
        </div>

        <hr class="col-span-3" />
        <div class="col-span-3 text-center">
          <div class="font-bold text-salmon">Need to see additional payment history?</div>
          <div class="mt-2">
            Call Customer Service at
            <a
              href="tel:18885369600"
              target="_blank"
              class="underline"
              >1-888-536-9600</a
            >
          </div>
        </div>
      </div>
      <div
        v-else
        class="text-center">
        <div class="mb-1 font-bold text-salmon">No payments found</div>
        <p class="text-sm">
          To schedule a payment, click
          <strong>Make a Payment</strong> and<br />
          choose a future date that's within the next 30 days.
        </p>
      </div>
    </AppPanel>

    <slot></slot>
  </div>
</template>

<script>
  export default {
    name: 'PaymentHistory',
    props: {
      payments: {
        type: Array,
        default: () => [],
      },
      promiseToPay: {
        type: Object,
        default: () => {},
      },
    },
    methods: {
      isPromiseToPay(date) {
        return this.promiseToPay?.date === date
      },
      handleDetailsToggle(isOpen) {
        this.trackEvent('trigger_payment_history_clicked')
      },
    },
  }
</script>
