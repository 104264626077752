<template>
  <BzlModal
    :label="updateTitle"
    :inner-class="`lg:w-full
    ${paymentSteps.entered ? 'h-full' : null} md:h-auto
    flex
    flex-col`"
    @sl-after-hide="$emit('dismiss')">
    <PaymentWizardStep1
      v-if="!paymentSteps.entered"
      :autopay-draft-date="$store.state.billing.debitDate"
      v-bind="$attrs"
      v-on="$listeners"
      @cancel="dismiss()"
      @proceed="reviewPayment($event)"></PaymentWizardStep1>
    <PaymentWizardStep2
      v-if="paymentSteps.entered && !paymentSteps.sent"
      v-bind="paymentDetails"
      @go-back="editPayment()"
      @approve="submitPayment()"></PaymentWizardStep2>
    <PaymentWizardStep3
      v-if="paymentSteps.entered && paymentSteps.sent"
      v-bind="paymentDetails"
      :response-status="responseStatus"
      @close="dismiss()"></PaymentWizardStep3>
  </BzlModal>
</template>

<script>
  import PaymentWizardStep1 from './step1.vue'
  import PaymentWizardStep2 from './step2.vue'
  import PaymentWizardStep3 from './step3.vue'

  /*
        Steps:
            (1) enter payment details OR make one-time-payment OR add new payment method (last 2 option closes the wizard and shows the CSG widget)
            (2) review if payment details are correct and approve
            (3) submit payment and wait for response
    */

  export default {
    name: 'PaymentWizard',
    components: {
      PaymentWizardStep1,
      PaymentWizardStep2,
      PaymentWizardStep3,
    },
    props: {
      autopayDraftDate: {
        type: String, // "YYYY-MM-DD"
        default: null,
      },
    },

    data: () => ({
      paymentSteps: {
        entered: false,
        sent: false,
      },
      paymentDetails: null, // populated by step1's submission
      responseStatus: false,
    }),
    mounted() {
      // this.trackEvent('trigger_make_a_payment_clicked')
    },
    computed: {
      updateTitle() {
        if (!this.paymentSteps.entered) {
          return 'Step 1: Enter payment details'
        } else if (this.paymentSteps.entered && !this.paymentSteps.sent) {
          return 'Step 2: Confirm payment details'
        } else if (this.paymentSteps.entered && this.paymentSteps.sent) {
          return 'Step 3: Confirmation'
        }
      },
    },
    methods: {
      dismiss() {
        this.trackEvent('P_PAY_NOW_CLOSED')
        this.$emit('dismiss')
      },
      reviewPayment(paymentDetails) {
        this.trackEvent('trigger_review_or_modify_payment_clicked')
        this.paymentDetails = paymentDetails
        this.paymentSteps.entered = true
      },
      editPayment() {
        this.trackEvent('trigger_re_enter_payment_info')
        this.paymentDetails = null
        this.paymentSteps.entered = false
      },
      submitPayment() {
        this.trackEvent('trigger_submit_payment_clicked')

        this.$emit('submit-payment', this.paymentDetails, (status) => {
          this.responseStatus = status
          this.paymentSteps.sent = true
        })
      },
    },
  }
</script>
