<template>
  <BzlModal
    label="Enable AutoPay?"
    @sl-after-hide="$emit('dismiss')">
    <div class="space-y-4">
      <hr />
      <div class="font-bold">
        It looks like you’ve just added a payment method. Would you like to enable AutoPay for it?
      </div>
      <div>
        AutoPay will take effect on your next statement. Your full balance due will be paid
        automatically each month using the selected payment method.
      </div>
      <AppRadioPanel
        v-for="pm in paymentMethods"
        :key="pm.id"
        :is-payment-method="true"
        :payment-method="pm"
        :is-selected="pm === selectedPaymentMethod"
        @click.native="select(pm)"></AppRadioPanel>
      <div class="flex w-full flex-row justify-between">
        <BzlButton
          variant="text"
          @click="$emit('dismiss')">
          Cancel
        </BzlButton>
        <BzlButton
          variant="primary"
          :disabled="selectedPaymentMethod === null"
          @click="confirm()">
          Confirm
        </BzlButton>
      </div>
    </div>
  </BzlModal>
</template>

<script>
  export default {
    name: 'EnableAutopay',
    props: {
      paymentMethods: {
        type: Array,
        default: () => [],
      },
    },
    data: () => ({
      selectedPaymentMethod: null,
    }),
    methods: {
      select(pm) {
        this.selectedPaymentMethod = pm
      },
      confirm() {
        this.trackEvent('PT_SUBMIT_AUTOPAY')
        this.$emit('confirm', this.selectedPaymentMethod, () => {
          this.$emit('dismiss')
        })
      },
    },
  }
</script>
