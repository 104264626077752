export default {
  namespaced: true,

  state: {
    alert: null,
  },

  mutations: {
    SET_ALERT(state, payload) {
      state.alert = payload
    },
  },
  actions: {
    setAlert({ commit }, payload = null) {
      commit('SET_ALERT', payload)
    },
  },
}
