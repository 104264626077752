<template>
  <div>
    <AppPanel
      v-if="isSummary"
      label="Account"
      spacing="none"
      route-name="account"
      class="hidden h-full lg:block">
      <div class="flex h-full flex-col items-stretch justify-between">
        <div class="flex grow flex-col justify-center border-b border-gray py-2 last:border-0">
          <div class="font-bold">Account Number</div>
          <div>
            {{ accountNumber }}
          </div>
        </div>
        <div class="flex grow flex-col justify-center border-b border-gray py-2 last:border-0">
          <div class="font-bold">Account Owner</div>
          <div>{{ loginName }}</div>
        </div>
        <div class="flex grow flex-col justify-center border-b border-gray py-2 last:border-0">
          <div class="font-bold">My Address</div>
          <div>
            {{ fullServiceAddress || '-' }}
          </div>
        </div>
        <div class="flex grow items-center justify-between border-b border-gray py-2 last:border-0">
          <div>
            <div class="font-bold">Preferred E-mail</div>
            <div>{{ customerInfo.emailAddress || '-' }}</div>
          </div>
          <BzlButton
            variant="default"
            @click.prevent="modals.preferredEmail = true">
            Change
          </BzlButton>
        </div>
      </div>
    </AppPanel>
    <AppPanel
      v-else
      variant="alternate">
      <div>Account Details</div>
      <div class="flex flex-col justify-between gap-4 lg:flex-row">
        <div class="flex w-full flex-col gap-4 border-b border-gray pb-4 lg:border-b-0 lg:border-r">
          <div class="text-2xl font-bold">Hi, {{ loginName }}!</div>
          <div>
            <div>{{ serviceAddress.addressLine1 || '-' }}</div>
            <div>
              {{ serviceAddress.city || '-' }}, {{ serviceAddress.state || '-' }}
              {{ serviceAddress.postalCode || '-' }}
            </div>
          </div>
          <div>
            <div>
              Account Number:
              <span>
                {{ accountNumber }}
              </span>
            </div>
          </div>
          <div>
            <div>
              Notification E-mail:<span>
                {{ customerInfo.emailAddress || '-' }}
              </span>
            </div>

            <a
              href="#"
              class="font-bold text-blue underline hover:text-salmon"
              @click.prevent="modals.preferredEmail = true">
              (Change)
            </a>
          </div>
        </div>
        <div class="flex w-full flex-col items-center justify-center gap-2 text-center">
          <span
            >You’re signed in as
            <div class="font-bold">
              {{ loginEmail || '-' }}
            </div></span
          >
          <BzlButton
            :m-full="true"
            variant="primary"
            @click="logout()">
            Sign Out
          </BzlButton>
        </div>
      </div>
    </AppPanel>

    <BzlModal
      :open="modals.preferredEmail"
      label="Preferred E-mail Address"
      @sl-after-hide="modals.preferredEmail = false">
      <div class="space-y-4">
        <input
          ref="newEmail"
          v-model="newEmail"
          :placeholder="customerInfo.emailAddress"
          type="email"
          class="w-full rounded border border-gray-dark text-center"
          :class="errors.email ? 'border-red-600' : null"
          @keydown.enter="changePreferredEmail()"
          @keydown.esc="modals.preferredEmail = false" />
        <p class="text-sm">
          We will send marketing and promotional materials to this e-mail address. If you have
          Ecopay enabled, we will send electronic copies of your monthly statements here as well.
        </p>
      </div>
      <hr class="mt-4 pt-4 lg:mt-8" />
      <div class="flex w-full flex-row justify-between">
        <BzlButton
          variant="text"
          @click="modals.preferredEmail = false">
          Cancel
        </BzlButton>
        <BzlButton
          variant="primary"
          :disabled="!hasChanged"
          @click="changePreferredEmail()">
          Save changes
        </BzlButton>
      </div>
    </BzlModal>
  </div>
</template>

<script>
  import LegalStuff from '../../components/LegalStuff/index.vue'
  export default {
    name: 'AccountDetails',
    components: {
      LegalStuff,
    },
    props: {
      accountNumber: {
        type: String,
        required: true,
      },
      customerInfo: {
        type: Object,
        required: true,
      },
      serviceAddress: {
        type: Object,
        required: true,
      },
      isSummary: {
        type: Boolean,
        default: false,
        required: false,
      },
      loginEmail: {
        type: String,
        default: '',
        required: false,
      },
    },
    data: () => ({
      newEmail: null,
      errors: {
        email: false,
      },
      modals: {
        preferredEmail: false,
      },
    }),
    computed: {
      fullServiceAddress() {
        const { addressLine1, addressLine2, city, state, postalCode } = this.serviceAddress
        let res = ''
        res += addressLine1
        res += addressLine2 && addressLine2.length ? ` ${addressLine2}` : ''
        res += `, ${city}, ${state} ${postalCode}`
        return res
      },
      hasChanged() {
        return this.customerInfo.emailAddress !== this.newEmail
      },
      loginName() {
        if (!this.customerInfo) {
          return 'Customer'
        }

        const name = `${this.customerInfo.firstName} ${this.customerInfo.lastName}`
        const lowercaseName = name.toLowerCase()
        const words = lowercaseName.split(' ')
        const capitalizedWords = words.map((word) => {
          return word.charAt(0).toUpperCase() + word.slice(1)
        })
        const capitalizedName = capitalizedWords.join(' ')
        return capitalizedName
      },
    },
    methods: {
      changePreferredEmail() {
        this.errors.email = false

        if (this.isValidEmail(this.newEmail)) {
          // mixin
          this.$emit('change-preferred-email', this.newEmail, () => {
            this.modals.preferredEmail = false
          })
        } else {
          this.errors.email = true
          this.$store.dispatch(
            'alerts/setAlert',
            {
              message: 'Invalid e-mail address. Please try again',
            },
            { root: true }
          )
        }
      },
      logout() {
        this.trackEvent('trigger_user_logged_out')
        this.$emit('logout')
      },
    },
  }
</script>
