import { MOBILE_APP_BANNER_HIDDEN_KEY } from '*/LocalStorageKeys.js'

export default {
  data() {
    return {
      isMobileAppBannerVisible: true,
      closedMobileAppBanner: false,
    }
  },
  methods: {
    hasClosedMobileAppBanner() {
      return JSON.parse(window.localStorage.getItem(MOBILE_APP_BANNER_HIDDEN_KEY)) === true
    },
    closeMobileAppBanner() {
      this.isMobileAppBannerVisible = false
      this.closedMobileAppBanner = true
      window.localStorage.setItem(MOBILE_APP_BANNER_HIDDEN_KEY, JSON.stringify(true))
    },
    clearPersistence() {
      window.localStorage.removeItem(MOBILE_APP_BANNER_HIDDEN_KEY)
    },
  },
  mounted() {
    // from the hasMobileAppBanner
    if (this.hasClosedMobileAppBanner()) {
      this.closeMobileAppBanner()
    }
  },
}
