import gql from 'graphql-tag'
import { DateTime } from 'luxon'
import { openPdfInNewTab } from '#/mixins.js'

export default {
  namespaced: true,

  state: {
    allStatements: [],
    popupsAreBlocked: false,
  },

  getters: {},

  mutations: {
    SET_STATEMENTS(state, payload) {
      state.allStatements = []

      payload.forEach((statement) => {
        const statementYear = DateTime.fromISO(statement.date).year.toString()
        let statementGroup = state.allStatements.find((g) => g.year === statementYear)

        if (!statementGroup) {
          statementGroup = {
            year: statementYear,
            monthlies: [],
          }

          state.allStatements.push(statementGroup)
        }

        statementGroup.monthlies.push({
          amountDue: statement.balanceStv / 100,
          datePosted: statement.date,
        })
      })
    },
    SET_POPUP_STATUS(state, payload) {
      state.popupsAreBlocked = payload
    },
  },

  actions: {
    initialize({ dispatch }) {
      return Promise.all([dispatch('_getStatements')])
    },
    _getStatements({ commit }) {
      return new Promise((resolve, reject) => {
        window.$apollo.addSmartQuery('getStatementBalance', {
          query: gql`
            query getStatementBalance {
              getStatementBalance {
                balanceStv
                date
              }
            }
          `,
          result: (r) =>
            r.data?.getStatementBalance &&
            resolve(commit('SET_STATEMENTS', r.data.getStatementBalance)),
          error: (e) => reject(e),
        })
      })
    },
    getBillExplainerUri({ commit, dispatch }) {
      commit('LOCK_APP', null, { root: true })

      return window.$apollo
        .query({
          query: gql`
            query getBillExplainerUri {
              getBillExplainerUri {
                uri
              }
            }
          `,
        })
        .then((r) => {
          const uri = r.data?.getBillExplainerUri?.uri
          let newTab

          if (uri) {
            newTab = window.open(uri, '_blank')

            if (!newTab || newTab.closed || typeof newTab.closed === 'undefined') {
              commit('SET_POPUP_STATUS', true)

              return dispatch(
                'alerts/setAlert',
                {
                  message:
                    'Please disable your pop-up blocker for My Account/Breezeline to view the Bill Explainer.',
                },
                { root: true }
              )
            } else {
              commit('SET_POPUP_STATUS', false)
            }
          }
        })
        .finally(() => {
          commit('UNLOCK_APP', null, { root: true })
        })
    },
    downloadStatement({ commit, dispatch }, statement) {
      commit('LOCK_APP', null, { root: true })
      // notice this is not a smart query
      return window.$apollo
        .query({
          query: gql`
            query getStatementPdf($cycleDate: String!) {
              getStatementPdf(cycleDate: $cycleDate) {
                pdfBase64
              }
            }
          `,
          variables: {
            cycleDate: statement.datePosted,
          },
        })
        .then((r) => {
          if (r.data?.getStatementPdf?.pdfBase64) {
            const newTab = openPdfInNewTab(r.data.getStatementPdf.pdfBase64)

            if (!newTab || newTab.closed || typeof newTab.closed === 'undefined') {
              commit('SET_POPUP_STATUS', true)

              return dispatch(
                'alerts/setAlert',
                {
                  message:
                    'Please disable your pop-up blocker for My Account/Breezeline to download your statements.',
                },
                { root: true }
              )
            } else {
              commit('SET_POPUP_STATUS', false)
            }
          }
        })
        .catch((e) => {
          console.error(e)

          return dispatch(
            'alerts/setAlert',
            {
              message: 'Unable to download statement. Please contact Support.',
            },
            { root: true }
          )
        })
        .finally(() => {
          commit('UNLOCK_APP', null, { root: true })
        })
    },
  },
}
