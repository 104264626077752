import gql from 'graphql-tag'

export default {
  namespaced: true,

  state: {
    accountNumber: null,
    marketingId: null,
    customerInfo: {
      firstName: null,
      lastName: null,
      salutation: null,
      emailAddress: null, // aka preferred email
      homePhone: null,
      businessPhone: null,
      referralCode: null,
    },
    serviceAddress: {
      addressLine1: null,
      addressLine2: null,
      city: null,
      postalCode: null,
      state: null,
    },
    outageInfo: {
      activeOutage: null,
      etr: null,
      outageUrl: null,
      zip: null,
    },
    hsdInfo: [],
    currentPlan: {
      code: null,
      down: null,
      isEligibleForUpgrade: null,
      name: null,
      up: null,
    },
  },

  getters: {},

  mutations: {
    SET_ACCOUNT_NUMBER(state, payload) {
      state.accountNumber = payload
    },
    SET_CUSTOMER_INFO({ customerInfo }, payload) {
      customerInfo = Object.assign(customerInfo, payload)
      delete customerInfo.__typename
    },
    SET_SERVICE_ADDRESS({ serviceAddress }, payload) {
      serviceAddress = Object.assign(serviceAddress, payload)
      delete serviceAddress.__typename
    },
    SET_OUTAGE_INFO({ outageInfo }, payload) {
      outageInfo = Object.assign(outageInfo, payload)
      delete outageInfo.__typename
    },
    SET_HSD_INFO(state, payload) {
      state.hsdInfo = payload
    },
    SET_CURRENT_PLAN(state, plan) {
      state.currentPlan = plan
    },
    SET_MARKETING_ID(state, payload) {
      state.marketingId = payload
      window.marketingId = payload
    },
  },

  actions: {
    initialize({ dispatch }) {
      return Promise.all([
        dispatch('_getAccountNumber'),
        dispatch('_getMarketingId'),
        dispatch('_getCustomerInfo'),
        dispatch('_getServiceAddress'),
        dispatch('_getOutageInfo'),
        dispatch('_setClientId'),
        dispatch('_getHsdInfo').then(() => dispatch('parseHsdInfo')),
        // dispatch('_testEndpoint'),
      ])
        .then((all) => {
          // note: this applies for this whole module. if further specificity is required, we need to adjust the modulesStatus to have a deeper scope.
          dispatch('moduleIsReady', { name: 'account', result: true }, { root: true })
          return all
        })
        .catch((e) => {
          console.error(e)
          dispatch(
            'moduleIsReady',
            { name: 'account', result: { error: e } },
            { root: { error: e } }
          )
        })
    },
    _getAccountNumber({ commit }) {
      return new Promise((resolve, reject) => {
        window.$apollo.addSmartQuery('getAccountNumber', {
          query: gql`
            query getAccountNumber {
              getAccountNumber
            }
          `,
          result: (r) =>
            r.data?.getAccountNumber &&
            resolve(commit('SET_ACCOUNT_NUMBER', r.data.getAccountNumber)),
          error: (e) => reject(e),
        })
      })
    },
    _getCustomerInfo({ commit }) {
      return new Promise((resolve, reject) => {
        window.$apollo.addSmartQuery('getCustomerInfo', {
          query: gql`
            query getCustomerInfo {
              getCustomerInfo {
                firstName
                lastName
                salutation
                emailAddress
                homePhone
                businessPhone
                referralCode
              }
            }
          `,
          result: (r) =>
            r.data?.getCustomerInfo && resolve(commit('SET_CUSTOMER_INFO', r.data.getCustomerInfo)),
          error: (e) => reject(e),
        })
      })
    },
    _getMarketingId({ commit }) {
      return new Promise((resolve, reject) => {
        window.$apollo.addSmartQuery('getMarketingId', {
          query: gql`
            query getMarketingId {
              getMarketingId {
                marketingIdBase64
              }
            }
          `,
          result: (r) => {
            r.data?.getMarketingId &&
              resolve(commit('SET_MARKETING_ID', r.data.getMarketingId.marketingIdBase64))
            window.gtag('config', import.meta.env.VITE_GTM_TRACKING_ID, {
              app_name: import.meta.env.VITE_APP_NAME,
              send_page_view: false,
              user_id: r.data?.getMarketingId?.marketingIdBase64 ?? null,
            })
          },
          error: (e) => reject(e),
        })
      })
    },
    _getServiceAddress({ commit }) {
      return new Promise((resolve, reject) => {
        window.$apollo.addSmartQuery('getServiceAddress', {
          query: gql`
            query getServiceAddress {
              getServiceAddress {
                addressLine1
                addressLine2
                city
                postalCode
                state
              }
            }
          `,
          result: (r) =>
            r.data?.getServiceAddress &&
            resolve(commit('SET_SERVICE_ADDRESS', r.data.getServiceAddress)),
          error: (e) => reject(e),
        })
      })
    },
    _getOutageInfo({ commit }) {
      return new Promise((resolve, reject) => {
        window.$apollo.addSmartQuery('getOutageInfo', {
          query: gql`
            query outage {
              getOutageInfo {
                activeOutage
                etr
                outageUrl
                zip
              }
            }
          `,
          result: (r) =>
            r.data?.getOutageInfo && resolve(commit('SET_OUTAGE_INFO', r.data.getOutageInfo)),
          error: (e) => reject(e),
        })
      }).catch((e) => {
        //
      })
    },
    _getHsdInfo({ commit }) {
      return new Promise((resolve, reject) => {
        window.$apollo.addSmartQuery('getHsdInfo', {
          query: gql`
            query getHsdInfo {
              getHsdInfo {
                hsdCurrentPriceStv
                hsdEverydayPriceStv
                hsdProductCode
                hsdProductName
                hsdPromotionEnabled
                hsdPromotionExpirationDate
                hsdSpeedMpbs
                hsdSpeedUpMbps
              }
            }
          `,
          result: (r) => r.data?.getHsdInfo && resolve(commit('SET_HSD_INFO', r.data.getHsdInfo)),
          error: (e) => reject(e),
        })
      })
    },
    _setClientId({ dispatch }) {
      return window.$apollo
        .mutate({
          mutation: gql`
            mutation SetClientId($clientId: String!) {
              setClientId(clientId: $clientId) {
                message
              }
            }
          `,
          variables: {
            clientId: 'WEB',
          },
        })
        .catch((error) => {
          console.error(error)

          return dispatch(
            'alerts/setAlert',
            {
              message: 'System error. Please try again later, or contact support.',
            },
            {
              root: true,
            }
          )
        })
    },
    parseHsdInfo({ state, commit }) {
      const info = state.hsdInfo.find((info) => info.hsdProductCode.toLowerCase().includes('air'))

      if (info) {
        commit('SET_CURRENT_PLAN', {
          code: info.hsdProductCode,
          down: info.hsdSpeedMpbs,
          isEligibleForUpgrade: true,
          name: info.hsdProductName,
          up: info.hsdSpeedUpMbps,
        })
      } else {
        commit('SET_CURRENT_PLAN', {
          code: null,
          down: null,
          isEligibleForUpgrade: false,
          name: null,
          up: null,
        })
      }
    },
    changePreferredEmail({ commit, dispatch }, newEmail) {
      commit('LOCK_APP', null, { root: true })

      return window.$apollo
        .mutate({
          mutation: gql`
            mutation updateCustomerInfo($contactInfo: CustomerInfoInput) {
              updateCustomerInfo(contactInfo: $contactInfo) {
                emailAddress
              }
            }
          `,
          variables: {
            contactInfo: {
              emailAddress: newEmail,
            },
          },
        })
        .then((r) => {
          if (r.errors) throw r.errors

          // Note: this is not the same as re-dispatching _getCustomerInfo()
          return window.$apollo.queries.getCustomerInfo.refetch()
        })
        .catch((e) => {
          console.error(e)

          return dispatch(
            'alerts/setAlert',
            {
              message: 'System error. Please try again later, or contact support.',
            },
            { root: true }
          )
        })
        .finally(() => {
          commit('UNLOCK_APP', null, { root: true })
        })
    },
    _testEndpoint() {
      return new Promise((resolve, reject) => {
        setTimeout(() => {
          resolve(true)
        }, 5000)
      })
    },
  },
}
