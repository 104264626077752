<template>
  <Step heading="Step 1: Enter payment details">
    <Substep label="Payment Amount">
      <AppRadioPanel
        :is-selected="isSelected('paymentAmount', 'amountDue')"
        @click.native="select('paymentAmount', 'amountDue')">
        <template #label>
          <span class="font-bold lg:text-lg">Amount due</span>
        </template>
        <template #value>
          <span class="font-bold text-navy-dark lg:text-lg">${{ amountDue.toFixed(2) }}</span>
        </template>
      </AppRadioPanel>
      <AppRadioPanel
        :is-selected="isSelected('paymentAmount', 'customAmount')"
        @click.native="select('paymentAmount', 'customAmount')">
        <template #label>
          <span class="font-bold lg:text-lg">Partial amount</span>
        </template>
        <template #value>
          <input
            v-model.number="values.paymentAmount"
            type="number"
            placeholder="$0.00"
            class="w-20 rounded border border-navy-dark text-right lg:w-32"
            :class="errors.paymentAmount ? 'border-red-600' : null" />
        </template>
      </AppRadioPanel>
    </Substep>
    <Substep label="Payment Date">
      <AppRadioPanel
        :is-selected="isSelected('paymentDate', 'today')"
        @click.native="select('paymentDate', 'today')">
        <template #label>
          <span class="font-bold lg:text-lg">Today</span>
        </template>
        <template #value>
          <span class="font-bold lg:text-lg">{{
            toFriendlyDate(new Date(), 'full-truncated')
          }}</span>
        </template>
      </AppRadioPanel>

      <AppRadioPanel
        v-if="amountDue > 0 && dueDate !== null && !isTodayOrBefore(dueDate)"
        :is-selected="isSelected('paymentDate', 'due')"
        @click.native="select('paymentDate', 'due')">
        <template #label>
          <span class="font-bold lg:text-lg">Due Date</span>
        </template>
        <template #value>
          <span class="font-bold lg:text-lg">{{ toFriendlyDate(dueDate, 'localized-date-with-abbreviated-month') }}</span>
        </template>
      </AppRadioPanel>
      <AppRadioPanel
        :is-selected="isSelected('paymentDate', 'scheduled')"
        :is-disabled="isOTP"
        class="overflow-visible"
        @click.native="isOTP ? null : select('paymentDate', 'scheduled')">
        <template #label>
          <span class="font-bold lg:text-lg">Other Date</span>
        </template>
        <template #value>
          <vc-date-picker
            v-if="hasAutopayDraftDates"
            v-model="values.paymentDate"
            class="inline-block h-full"
            :available-dates="[
              { start: minDate, end: hasAutopayDraftDates.start },
              { start: hasAutopayDraftDates.end, end: maxDate },
            ]"
            popover-direction="top"
            mode="date">
            <template #default="{ inputValue, togglePopover }">
              <button
                class="flex items-center"
                @click="togglePopover()">
                <input
                  readonly
                  :value="inputValue"
                  class="w-20 rounded border border-navy-dark p-2 text-right outline-none lg:w-32" />
              </button>
            </template>
          </vc-date-picker>
          <vc-date-picker
            v-else
            v-model="values.paymentDate"
            class="inline-block h-full"
            :available-dates="{ start: minDate, end: maxDate }"
            popover-direction="top"
            mode="date">
            <template #default="{ inputValue, togglePopover }">
              <button
                class="flex items-center"
                @click="togglePopover()">
                <input
                  readonly
                  :value="inputValue"
                  class="w-20 rounded border border-navy-dark p-2 text-right outline-none lg:w-32" />
              </button>
            </template>
          </vc-date-picker>
        </template>
      </AppRadioPanel>
    </Substep>
    <Substep label="Payment Method">
      <AppRadioPanel
        v-for="pm in paymentMethods"
        :key="pm.id"
        :is-payment-method="true"
        :payment-method="pm"
        :is-selected="isSelected('paymentMethod', pm)"
        @click.native="select('paymentMethod', pm)"></AppRadioPanel>
      <AppRadioPanel
        :is-selected="isSelected('paymentMethod', 'otp')"
        :is-disabled="isScheduledPayment"
        @click.native="select('paymentMethod', 'otp')">
        <template #label>
          <div>
            <div class="text-lg font-bold text-navy-dark">Make a one-time payment today</div>
            <div class="font-book text-sm">Using a new payment method</div>
          </div>
        </template>
      </AppRadioPanel>
      <div
        v-if="paymentMethods.length < maxPaymentMethods"
        :autopay-payment-method="$store.state.discounts.autopay.paymentMethod"
        class="rounded-md border border-gray p-4 text-center text-sm">
        <span class="hover:text-salmon">
          <i class="fas fa-plus"></i>&nbsp;
          <a
            class="cursor-pointer underline"
            @click="$emit('add-payment-method')">
            Save a new payment method.
          </a>
        </span>
      </div>
    </Substep>

    <hr />
    <template #footer>
      <div class="items center flex w-full justify-between">
        <BzlButton
          variant="text"
          @click="$emit('dismiss')">
          Cancel
        </BzlButton>
        <BzlButton
          variant="primary"
          @click="proceed()">
          Continue
        </BzlButton>
      </div>
    </template>
  </Step>
</template>

<script>
  import { DateTime } from 'luxon'
  import Step from './step.vue'
  import Substep from './substep.vue'
  import CalendarIcon from './calendar-icon.svg'

  export default {
    name: 'PaymentWizardStep1',
    components: {
      Step,
      Substep,
    },
    props: {
      amountDue: {
        type: Number,
        default: 0,
      },
      autopayDraftDate: {
        type: String, // "YYYY-MM-DD"
        default: null,
      },
      dueDate: {
        type: String, // "YYYY-MM-DD"
        default: null,
      },
      paymentMethods: {
        type: Array,
        default: () => [],
      },
      maxPaymentMethods: {
        type: Number,
        default: 4,
      },
      scheduledPayments: {
        type: Array,
        default: () => [],
      },
    },
    data: () => ({
      date: new Date(),
      maxDate: null,
      minDate: null,

      selections: {
        paymentAmount: null, // 'amountDue' or 'customAmount'
        paymentDate: null, // 'today' or 'due' or 'scheduled'
        paymentMethod: null, // will default to defaultPM on mount, if it exists
      },
      values: {
        paymentAmount: null,
        paymentDate: null,
        // no paymentMethod (selections.paymentMethod is already the value!)
      },
      errors: {
        paymentAmount: false,
      },
      CalendarIcon: {
        src: CalendarIcon,
        name: 'Calendar',
      },
    }),
    computed: {
      isScheduledPayment() {
        return this.selections.paymentDate === 'scheduled'
      },
      isOTP() {
        return this.selections.paymentMethod === 'otp'
      },
      hasExpired() {
        const expirationDate = this.monthsFromDate(
          1,
          this.selections.paymentMethod.expiresOn,
          'MM/yyyy'
        )
        const paymentDate =
          this.values.paymentDate && DateTime.fromJSDate(this.values.paymentDate).startOf('day')

        return expirationDate && paymentDate && expirationDate < paymentDate
      },
      hasAutopayDraftDates() {
        if (this.autopayDraftDate) {
          const disabledDate = new Date(this.autopayDraftDate + 'T00:00:00-04:00') // Adjusted for EDT

          if (disabledDate >= this.minDate && disabledDate <= this.maxDate) {
            const startDate = new Date(disabledDate)
            startDate.setDate(startDate.getDate() - 1)
            startDate.setHours(23, 59, 59, 999) // Set the time to the end of the previous day

            const endDate = new Date(disabledDate)
            endDate.setDate(endDate.getDate() + 1)
            endDate.setHours(0, 0, 0, 0) // Set the time to the start of the next day

            return {
              start: startDate,
              end: endDate,
              isDisabled: true,
            }
          }
        }
        return ''
      },
    },

    mounted() {
      // default selections
      this.selections.paymentAmount = 'amountDue'
      this.selections.paymentDate = 'today'
      this.selections.paymentMethod = this.paymentMethods.find((pm) => pm.isDefault) ?? ''
      this.values.paymentDate = this.daysFromToday(1)
      this.minDate = this.daysFromToday(1)
      this.maxDate = this.daysFromToday(30)
    },
    methods: {
      isSelected(key, value) {
        return this.selections[key] === value
      },
      select(key, value) {
        // for selections, not values
        this.selections[key] = value

        if (key === 'paymentAmount') {
          if (value === 'amountDue') this.trackEvent('trigger_pay_full_amount_clicked')
          if (value === 'customAmount') this.trackEvent('trigger_pay_partial_amount_clicked')
        }

        if (key === 'paymentDate') {
          if (value === 'today') this.trackEvent('P_DATE_SELECTED_TODAY')
          // if (value === 'due') this.trackEvent('???')
          if (value === 'scheduled') this.trackEvent('trigger_date_selected')
        }

        if (key === 'paymentMethod') {
          this.trackEvent('trigger_payment_method_option_clicked')

          // Force the user to select today for OTPs
          this.selections.paymentDate = value === 'otp' ? 'today' : this.selections.paymentDate
        }
      },
      proceed() {
        // Reset errors
        this.errors.paymentAmount = false

        const submitted = {
          paymentAmount: null,
          paymentDate: null,
          paymentMethod: null,
          isFuture: false,
        }

        if (this.selections.paymentAmount === 'amountDue') {
          submitted.paymentAmount = this.amountDue.toFixed(2) // forces the payment to a fixed amount of 2 decimal places and fixes any conversion issues
        } else {
          submitted.paymentAmount = this.values.paymentAmount?.toFixed(2) || '0.00'
        }

        if (this.selections.paymentDate === 'today') {
          submitted.paymentDate = this.toFriendlyDate(new Date(), 'ISO8601')
        } else if (this.selections.paymentDate === 'due') {
          submitted.paymentDate = this.toFriendlyDate(this.dueDate, 'ISO8601')
          submitted.isFuture = true
        } else {
          submitted.paymentDate = this.toFriendlyDate(this.values.paymentDate, 'ISO8601')
          submitted.isFuture = true
        }

        submitted.paymentMethod = this.selections.paymentMethod // {} or 'otp'

        if (isNaN(submitted.paymentAmount) || submitted.paymentAmount <= 0) {
          this.errors.paymentAmount = true
          this.$store.dispatch(
            'alerts/setAlert',
            {
              message: 'Error: Please enter a valid payment amount.',
            },
            { root: true }
          )
        } else if (submitted.paymentMethod === '') {
          this.$store.dispatch(
            'alerts/setAlert',
            {
              message: `Error: Please select a payment method.`,
            },
            { root: true }
          )
        } else if (this.hasExpired) {
          this.$store.dispatch(
            'alerts/setAlert',
            {
              message: `Error: Unable to create scheduled payment. Please check your credit/debit card's expiration date.`,
            },
            { root: true }
          )
        } else if (
          submitted.isFuture &&
          this.scheduledPayments.length >= (import.meta.env.VITE_MAX_SCHEDULED_PAYMENTS ?? 1)
        ) {
          this.$store.dispatch(
            'alerts/setAlert',
            {
              message: 'Error: You can only schedule up to one future dated payments at a time.',
            },
            { root: true }
          )
        } else {
          if (this.isOTP) {
            this.$emit('make-one-time-payment', submitted.paymentAmount)
          } else {
            this.$emit('proceed', submitted)
          }
        }
      },
    },
  }
</script>
