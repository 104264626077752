<template>
  <AppPanel
    label="Need Help?"
    trigger="trigger_need_help_click"
    variant="alternate"
    :button="true"
    spacing="none"
    padding="none"
    routeName="support">
    <p class="text-center">
      <a
        href="https://www.breezeline.com/support"
        target="_blank"
        rel="noopener noreferrer"
        class="flex w-full items-center justify-center gap-1 underline hover:text-salmon"
        @click="trackEvent('trigger_general_support_link_clicked')">
        General Support is available here <i class="fa fa-external-link"></i
      ></a>
    </p>
  </AppPanel>
</template>

<script>
  export default {
    name: 'NeedHelp',
  }
</script>
