<template>
  <div class="space-y-2">
    <label
      v-if="label && label.length > 0"
      class="block cursor-pointer text-base font-bold">
      {{ label }}
    </label>
    <slot></slot>
  </div>
</template>

<script>
  export default {
    name: 'Substep',
    props: {
      label: {
        type: String,
        default: null,
      },
    },
  }
</script>
