import { Alert } from '$/Alert.js'

export default {
  namespaced: true,
  state: {
    visible: true,
    notifications: [],
  },
  getters: {
    isVisible(state) {
      return state.visible
    },
    hasNotifications(state) {
      return state.notifications.length > 0
    },
  },
  mutations: {
    SET_NOTIFICATIONS(state, notifications) {
      state.notifications = notifications
    },
    SET_VISIBLE(state, visible) {
      state.visible = visible
    },
  },
  actions: {
    async addNotification({ commit, state }, alert) {
      if (!alert.variant) {
        alert.variant = 'warning'
      }

      const notifications = state.notifications

      notifications.push(new Alert(alert))

      commit('SET_VISIBLE', true)
      commit('SET_NOTIFICATIONS', notifications)
    },
    clearNotifications({ commit }) {
      commit('SET_NOTIFICATIONS', [])
    },
    removeNotification({ commit, state }, remove) {
      commit(
        'SET_NOTIFICATIONS',
        state.notifications.filter((alert) => alert === remove)
      )
    },
    hideNotifications({ commit }) {
      commit('SET_VISIBLE', false)
    },
  },
}
