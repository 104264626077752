import gql from 'graphql-tag'

export default {
  namespaced: true,

  state: {
    session: {
      accountId: null,
      nonce: null,
      sessionId: null,
      businessUnit: null,
    },
  },

  getters: {},

  mutations: {
    SET_SESSION({ session }, payload) {
      session = Object.assign(session, payload)
      delete session.__typename

      // Generate business unit
      if (session.accountId) {
        session.businessUnit = `${String(session.accountId).substr(0, 4)}-${String(
          session.accountId
        ).substr(4, 2)}00`
      }

      session.gateway = import.meta.env.VITE_MYACCOUNT_PAYMENT_WIDGET_GATEWAY
    },
  },

  actions: {
    initialize() {
      // Nothing to load initially (for now)
      return Promise.all([])
    },
    getWidgetSession({ commit, dispatch }) {
      if (Object.keys(window.$apollo.queries).includes('getWidgetSession')) {
        commit('LOCK_APP', null, { root: true })

        // before OTP or APM form is shown, session is always refreshed first
        return window.$apollo.queries.getWidgetSession.refetch().finally(() => {
          commit('UNLOCK_APP', null, { root: true })
        })
      } else {
        return new Promise((resolve, reject) => {
          window.$apollo.addSmartQuery('getWidgetSession', {
            query: gql`
              query getWidgetSession {
                getWidgetSession {
                  accountId
                  nonce
                  sessionId
                }
              }
            `,
            result: (r) => {
              if (r.data && r.data.getWidgetSession) {
                commit('SET_SESSION', r.data.getWidgetSession)
                resolve(true) // allow modal to show up
              }
            },
            error: (e) => reject(e),
          })
        }).catch((e) => {
          console.error(e)
          return (
            dispatch(
              'alerts/setAlert',
              {
                message: 'Error fetching payment widget session. Please contact Support.',
              },
              { root: true }
            ),
            delete window.$apollo.queries.getWidgetSession
          )
        })
      }
    },
  },
}
