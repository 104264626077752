import gql from 'graphql-tag'

export default {
  namespaced: true,

  state: {
    appointmentInfo: {
      message: null,
      rowCount: null,
      status: null,
      appointments: {
        appointmentDate: null,
        jobClass: null,
        jobNumber: null,
        jobStatus: null,
        jobType: null,
        jobTypeDescription: null,
        orderClass: null,
        orderNumber: null,
        technician: null,
        timeSlot: null,
        url: null,
      },
    },
  },

  getters: {},
  mutations: {
    SET_APPOINTMENT_INFO({ appointmentInfo }, payload) {
      appointmentInfo = Object.assign(appointmentInfo, payload)
      delete appointmentInfo.__typename
    },
  },
  actions: {
    initialize({ dispatch }) {
      return Promise.all([dispatch('_getAppointment')])
        .then((all) => {
          dispatch('moduleIsReady', { name: 'appointments', result: true }, { root: true })
          return all
        })
        .catch((e) => {
          dispatch(
            'moduleIsReady',
            { name: 'appointments', result: { error: e } },
            { root: { error: e } }
          )
        })
    },
    _getAppointment({ commit }) {
      return new Promise((resolve, reject) => {
        window.$apollo.addSmartQuery('getAppointment', {
          query: gql`
            query appointment {
              getAppointment {
                message
                rowCount
                status
                appointments {
                  appointmentDate
                  jobClass
                  jobNumber
                  jobStatus
                  jobType
                  jobTypeDescription
                  orderClass
                  orderNumber
                  technician
                  timeSlot
                  url
                }
              }
            }
          `,
          result: (r) => {
            r.data?.getAppointment && resolve(commit('SET_APPOINTMENT_INFO', r.data.getAppointment))
          },
          error: (e) => reject(e),
        })
      }).catch((e) => {
        throw e?.message?.includes('server error')
          ? 'appointment server error'
          : `appointment error: ${e}`
      })
    },
  },
}
