<template>
  <Step
    heading="Confirm payment details"
    class="pb-8 lg:pb-5">
    <p
      v-if="
        convertToPromiseToPay.canConvert &&
        scheduledPayments.length &&
        convertToPromiseToPay.priceInRange &&
        convertToPromiseToPay.dateInRange
      "
      class="text-lg font-bold lg:text-xl">
      You current have a payment scheduled for
      {{ toFriendlyDate(scheduledPayments[0]?.date, 'short-numeric-slash') }} to pay ${{
        scheduledPayments[0]?.amount
      }}. Since this covers your past due balance, we will leverage this as your Promise to Pay
      payment.
    </p>
    <div v-else>
      <p class="text-lg font-bold lg:pb-4 lg:text-2xl">
        You have chosen to pay your past due balance of ${{ paymentAmount }} on
        {{ toFriendlyDate(paymentDate, 'short-numeric-slash') }}
      </p>
      <hr class="border-gray" />
      <p class="text-lg font-bold lg:pt-4 lg:text-xl">Is this correct?</p>
    </div>

    <template #footer>
      <div class="flex w-full flex-col justify-between gap-4 lg:flex-row">
        <BzlButton
          variant="default"
          size="large"
          :m-full="true"
          @click="
            convertToPromiseToPay.priceInRange && convertToPromiseToPay.dateInRange
              ? $emit('cancel')
              : $emit('go-back', { paymentAmount: paymentAmount, paymentDate: paymentDate })
          ">
          {{ backLabel }}
        </BzlButton>
        <BzlButton
          :m-full="true"
          size="large"
          class="track"
          data-ga-category="Promise to Pay"
          :data-ga-action="getGaAction"
          data-ga-label="User clicked the submit promise to pay button"
          @click="$emit('approve', convertToPromiseToPay)">
          {{ submitText }}
        </BzlButton>
      </div>
    </template>
  </Step>
</template>

<script>
  import Step from './step.vue'

  export default {
    name: 'PromiseToPayStep2',
    components: {
      Step,
    },
    props: {
      paymentAmount: {
        type: String,
        required: true,
        validator: (val) => val > 0,
      },
      paymentDate: {
        type: String,
        required: true,
      },
      paymentType: {
        type: String,
        required: true,
      },
      minimumAmount: {
        type: Number,
        default: 0,
      },
      paymentMethod: {
        type: Object,
        required: true,
      },
      hasPromiseToPayScheduled: {
        type: Boolean,
        default: false,
      },
      daysPastDue: {
        type: Number,
        default: 0,
      },
      scheduledPayments: {
        type: Array,
        default: () => [],
      },
    },
    computed: {
      backLabel() {
        return this.convertToPromiseToPay.priceInRange && this.convertToPromiseToPay.dateInRange
          ? 'Cancel'
          : 'Edit'
      },
      submitText() {
        return this.convertToPromiseToPay.canConvert &&
          this.scheduledPayments.length &&
          this.convertToPromiseToPay.priceInRange &&
          this.convertToPromiseToPay.dateInRange
          ? 'Continue'
          : 'Submit'
      },
      getGaAction() {
        return this.submitText === 'Continue'
          ? 'trigger_confirm_promise_to_pay_w_fdp'
          : 'trigger_promise_to_pay_submit'
      },
      convertToPromiseToPay() {
        const obj = {
          dateInRange: false,
          priceInRange: false,
          canConvert: false,
          scheduledDate: null,
        }
        if (!this.hasPromiseToPayScheduled && this.scheduledPayments.length) {
          const scheduledDate = this.scheduledPayments[0]?.date
          const scheduledAmount = this.scheduledPayments[0]?.amount

          // prettier-ignore
          if (21 - (this.daysPastDue >= 33 ? 21 - (53 - this.daysPastDue) : 0) - this.howManyDaysFromToday(scheduledDate) >= 0) {
            obj.dateInRange = true
          }

          if (scheduledAmount >= this.minimumAmount) {
            obj.priceInRange = true
          }

          obj.canConvert = true
          obj.scheduledDate = scheduledDate
        }
        return obj
      },
    },
  }
</script>
