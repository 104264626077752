<template>
  <AppPanel
    label="Login Details"
    class="text-center">
    <div>
      <div class="font-book">Logged in as</div>
      <div class="text-lg font-bold">
        {{ email }}
      </div>
    </div>
    <BzlButton
      variant="danger"
      @click="logout()">
      Logout
      <i class="fas fa-sign-out-alt"></i>
    </BzlButton>
  </AppPanel>
</template>

<script>
  export default {
    name: 'LoginDetails',
    props: {
      email: {
        type: String,
        required: true,
      },
    },
    methods: {
      logout() {
        this.trackEvent('trigger_user_logged_out')
        this.$emit('logout')
      },
    },
  }
</script>
